import React, { useState, useEffect } from 'react';
import { fetchActiveChats, fetchChatMessages, addMessage } from '../../../features/chat/chatApi';
import { NatsManager } from '../../../classes/natsManager';

const QueryPage = () => {
    const [chatData, setChatData] = useState([]); // All chats
    const [selectedChat, setSelectedChat] = useState(null); // Current selected chat
    const [messages, setMessages] = useState([]); // Messages of the selected chat
    const [newMessage, setNewMessage] = useState(''); // Input for new message
    const [loading, setLoading] = useState(false); // Loading state for sending messages

    // Fetch all chats on component mount
    useEffect(() => {
        fetchActiveChatsData();

    }, []);

    // Fetch messages when a new chat is selected
    useEffect(() => {
        if (selectedChat) {
            fetchChatMessagesData(selectedChat._id);
            subscribeToChat(); // Subscribe to this specific chat's subject
        }
    }, [selectedChat]);

    // Fetch all active chats
    const fetchActiveChatsData = async () => {
        try {
            const response = await fetchActiveChats({});
            const sortedChats = response.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            setChatData(sortedChats); // Store sorted chats
        } catch (error) {
            console.error("Error fetching active chats:", error);
        }
    };

    // Fetch messages for a specific chat
    const fetchChatMessagesData = async (chatId) => {
        try {
            const response = await fetchChatMessages({ orderId: chatId });
            setMessages(response); // Store messages
        } catch (error) {
            console.error("Error fetching chat messages:", error);
        }
    };

    // Subscribe to the specific chat's subject
    const subscribeToChat = async () => {
        if (selectedChat) {
            const subject = `chat.${selectedChat._id}`;
            NatsManager.subscribe(subject, async (message) => {
                message = JSON.parse(message);
                if (message.orderId === selectedChat._id) {
                    setMessages((prevMessages) => {
                        const filteredMessages = prevMessages.filter((msg) => msg._id !== message._id);
                        const updatedMessages = [...filteredMessages, message];
                        console.log("Updated Messages:", updatedMessages);
                        return updatedMessages;
                    });
                } else {
                    console.warn("Message does not belong to the current chat:", message.orderId, selectedChat._id, JSON.parse(message));
                }
            });
        }
    };

    // Send a new message
    const handleSendMessage = async () => {
        if (!newMessage.trim()) return; // Avoid sending empty messages

        const newMessageObject = {
            _id: `msg-${Date.now()}-${Math.random().toString(36).substring(2, 15)}`,
            orderId: selectedChat._id,
            messageType: 'text',
            messageData: newMessage,
            isOps: true, // Marking as operator message
            createdAt: new Date().toISOString(),
        };

        // Optimistically update UI
        setMessages((prevMessages) => [...prevMessages, newMessageObject]);
        setNewMessage(''); // Clear input

        try {
            // setLoading(true);
            await addMessage({
                orderId: selectedChat._id,
                messageType: 'text',
                messageData: newMessage,
                isOps: true,
            });

            // Publish the message to the NATS subject
            const subject = `chat.${selectedChat._id}`; // Unique subject for the chat
            await NatsManager.send(subject, JSON.stringify(newMessageObject)); // Publish the new message to NATS

        } catch (error) {
            console.error("Error sending message:", error);
            // Revert optimistic update if needed
            setMessages((prevMessages) => prevMessages.filter((msg) => msg._id !== newMessageObject._id));
        } finally {
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', width: "75vw", height: '93vh', border: '1px solid #ccc' }}>
            {/* Chat List */}
            <div style={{ flex: 2, borderRight: '1px solid #ccc', overflowY: 'auto' }}>
                <h2 style={{ textAlign: 'center', paddingTop: "24px", paddingBottom: "23px", borderBottom: '1px solid #ccc' }}>Active Chats</h2>
                {chatData.length > 0 ? (
                    chatData.map((chat) => (
                        <div
                            key={chat._id}
                            style={{
                                padding: '1rem',
                                borderBottom: '1px solid #ddd',
                                cursor: 'pointer',
                                backgroundColor: selectedChat?._id === chat._id ? '#f0f8ff' : 'white',
                            }}
                            onClick={() => setSelectedChat(chat)}
                        >
                            <h4 >Order ID: {chat._id}</h4>
                            <p ><strong>Customer:</strong> {chat.customerData.email}</p>
                            <p><strong>Status:</strong> {chat.orderDetails.status}</p>
                        </div>

                    ))
                ) : (
                    <p style={{ textAlign: 'center' }}>No active chats available.</p>
                )}
            </div>

            {/* Chat Room */}
            <div style={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
                {selectedChat ? (
                    <>
                        {/* Chat Header */}
                        <div style={{ padding: '1rem', borderBottom: '1px solid #ccc' }}>
                            <h3>Chat with: {selectedChat.customerData.email}</h3>
                            <p>Order ID: {selectedChat._id}</p>
                        </div>

                        {/* Chat Messages */}
                        <div style={{ flex: 1, overflowY: 'auto', padding: '1rem' }}>
                            {messages.length > 0 ? (
                                messages.map((msg, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            marginBottom: '1rem',
                                            textAlign: msg.isOps ? 'right' : 'left',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                padding: '0.5rem 1rem',
                                                borderRadius: '15px',
                                                backgroundColor: msg.isOps ? '#d9f7be' : '#e6f7ff',
                                            }}
                                        >
                                            <p style={{ margin: 0 }}>{msg.messageData}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No messages yet in this chat.</p>
                            )}
                        </div>

                        {/* Input for sending new messages */}
                        <div style={{ padding: '1rem', borderTop: '1px solid #ccc' }}>
                            <input
                                type="text"
                                placeholder="Type your message..."
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                style={{
                                    width: '80%',
                                    padding: '0.5rem',
                                    marginRight: '1rem',
                                    border: '1px solid #ddd',
                                    borderRadius: '4px',
                                }}
                                disabled={loading}
                            />
                            <button
                                onClick={handleSendMessage}
                                style={{
                                    padding: '0.5rem 1.3rem',
                                    backgroundColor: '#007bff',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: loading ? 'not-allowed' : 'pointer',
                                }}
                                disabled={loading}
                            >
                                {loading ? 'Sending...' : 'Send'}
                            </button>
                        </div>
                    </>
                ) : (
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p>Select a chat to view messages.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QueryPage;
