import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logoutAsync, selectLoggedInUser } from '../AuthSlice'
import { useNavigate } from 'react-router-dom'
import { clearCart } from '../../cart/CartSlice'

export const Logout = () => {
    const dispatch=useDispatch()
    const loggedInUser=useSelector(selectLoggedInUser)
    const navigate=useNavigate()

    useEffect(()=>{
        dispatch(logoutAsync())
        dispatch(clearCart())
    },[dispatch])

    useEffect(()=>{
       
            navigate("/login")
        
    },[loggedInUser])

  return (
    <></>
  )
}
