import { CustomStorageManager } from '../../classes/storageManager'
import { axiosi } from '../../config/axios'

export const signup = async (cred) => {
    try {
        const res = await axiosi.post("https://orginv8.in:8443/auth/signup", cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const login = async (cred) => {
    try {
        const res = await axiosi.post("https://orginv8.in:8443/auth/login", cred);
        if (res.data.token) {
            await CustomStorageManager.store("token", res.data.token);
            await CustomStorageManager.store("userData", JSON.stringify(res.data));
        }
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

export const verifyOtp = async (cred) => {
    try {
        const res = await axiosi.post("https://orginv8.in:8443/auth/verify-otp", cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const resendOtp = async (cred) => {
    try {
        const res = await axiosi.post("https://orginv8.in:8443/auth/resend-otp", cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const forgotPassword = async (cred) => {
    try {
        const res = await axiosi.post("https://orginv8.in:8443/auth/forgot-password", cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const resetPassword = async (cred) => {
    try {
        const res = await axiosi.post("https://orginv8.in:8443/auth/reset-password", cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const checkAuth = async (cred) => {
    try {
        const token = await CustomStorageManager.fetch('token');
        const res = await axiosi.post("https://orginv8.in:8443/auth/check-auth", {
            "token": token,
        });
        return res.data
        // let logindata={
        //     "_id": "6719fa09b548dfd8c15dbcd9",
        //     "email": "ravikant@orginv8.com",
        //     "isVerified": true,
        //     "isAdmin": false
        // }
        // return logindata
    } catch (error) {
        throw error.response.data
    }
}
export const logout = async () => {
    try {
        await CustomStorageManager.clearAll();
        const res = await axiosi.get("https://orginv8.in:8443/auth/logout");
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

export const deleteuseraccount = async (payload) => {
    try {
        await CustomStorageManager.clearAll();
        const response = await axiosi.post('https://orginv8.in:8443/auth/delete', payload);
        return response
    } catch (error) {
        throw error.response
    }
};
