import React, { useState, useRef, useEffect } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import "./logs.css";

const ITEMS_PER_PAGE = 10;

const SearchOlderLogs = () => {
    const { macId } = useParams();
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeHeader, setActiveHeader] = useState(null);
    const navigate = useNavigate();
    const [range, setRange] = useState({});
    const workerRef = useRef(null);
    let unwantedKeys = ['macAddress', 'level', 'message'];

    useEffect(() => {
        workerRef.current = new Worker(new URL('./worker/sortWorker.js', import.meta.url));
        console.log(workerRef);
        workerRef.current.onmessage = (e) => {
            setLogs(e.data);
        };
        return () => {
            workerRef.current.terminate();
        };
    }, []);

    const handleFetchData = async () => {
        if (!startTime || !endTime) {
            alert("Please select both start and end times.");
            return;
        }
        if (new Date(startTime) >= new Date(endTime)) {
            alert("Start time must be before end time.");
            return;
        }

        const requestBody = {
            index: "device-logs",
            startDate: new Date(startTime).toISOString(),
            endDate: new Date(endTime).toISOString(),
            macId: macId,
        };

        try {
            const response = await fetch("https://orginv8.in:8443/device/searchLogsInTimeRange", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const data = await response.json();
            console.log("Fetched Data:", data);
            for (let i = 0; i < data.logs.length; i++) {
                data.logs[i]._source['timestamp'] = new Date(data.logs[i]._source['timestamp']).toLocaleString();
            }
            setLogs(data.logs || []);
            setCurrentPage(1);
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("Failed to fetch data. Check console for details.");
        }
    };

    const totalPages = Math.ceil(logs.length / ITEMS_PER_PAGE);
    const handlePageChange = (newPage) => {
        if (newPage < 1 || newPage > totalPages) return;
        setCurrentPage(newPage);
    };

    const currentLogs = logs.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const selectedValues = [];
    logs.map((log) => {
        Object.entries(log._source).map(([key]) => {
            if (!unwantedKeys.includes(key)) {
                selectedValues.push(key);
            }
        });
    });

    const handleSort = async (key) => {
        try {
            let currentRange = range[key] || "low-to-high";
            let newRange = currentRange === 'low-to-high' ? 'high-to-low' : 'low-to-high'

            workerRef.current.postMessage({
                data: logs,
                greaterFlag: currentRange === 'low-to-high' ? true : false,
                key: key
            });

            setRange({ ...range, [key]: newRange })
        } catch (error) {
            console.log("Error", error)
        }
    };

    const handleHeaderClick = (key) => {
        setActiveHeader((prevKey) => (prevKey === key ? null : key));
    };

    const renderLogs = () => (
        <div className="search-older-logs">
            <h1>Search Older Logs</h1>
            <div className="time-range-selector">
                <label>
                    Start Time:
                    <input
                        type="datetime-local"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                    />
                </label>
                <label>
                    End Time:
                    <input
                        type="datetime-local"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                    />
                </label>
                <button className="fetchBtn" onClick={handleFetchData}>Fetch Data</button>
            </div>

            <div className="logs-container">
                {currentLogs.length > 0 ? (
                    <>
                        <h2>Logs</h2>
                        <table>
                            <thead>
                                <tr>
                                    {Object.keys(currentLogs[0]._source)
                                        .filter((key) => selectedValues.includes(key))
                                        .map((key) => (
                                            <th className="headings" key={key} onClick={() => handleHeaderClick(key)}>
                                                <div>{key}</div>
                                                {activeHeader === key && (
                                                    <div className="limits" onClick={(e) => e.stopPropagation()}>
                                                        <button onClick={() => handleSort(key)}>
                                                            {range[key] === 'high-to-low' ? 'v' : '^'}
                                                        </button>
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentLogs.map((log, index) => (
                                    <tr key={index}>
                                        {Object.entries(log._source).map(
                                            ([key, value]) =>
                                                selectedValues.includes(key) && <td key={key}>{value}</td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="pagination">
                            <button onClick={() => handlePageChange(currentPage - 1)}>
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button onClick={() => handlePageChange(currentPage + 1)}>
                                Next
                            </button>
                        </div>
                    </>
                ) : (
                    <p>No logs available. Please fetch data.</p>
                )}
            </div>
        </div>
    );


    return (
        <div className="device-search">
            <button onClick={() => navigate(-1)} className="back-button">Go Back</button>
            {renderLogs()}
        </div>
    );
};

export default SearchOlderLogs;
