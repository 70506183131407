import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginOps } from '../../features/ops/opsApi';
import { useNavigate } from 'react-router-dom';

import '../css//LoginPage.css'; // Ensure this CSS file exists
import { CustomStorageManager } from '../../classes/storageManager';


const OpsLoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await loginOps({ email, password });
            if (response && response.token) {
                await CustomStorageManager.store('opsToken', response.token);
                await CustomStorageManager.store('opsEmail', response.user.email);
                await CustomStorageManager.store('opsMobileNumber', response.user.mobileNumber);
                navigate('/ops/dashboard');
            } else {
                console.error('Login failed: No token returned');
                setErrorMessage('Login failed: Please check your credentials.');
            }
        } catch (error) {
            console.error('Login failed:', error);
            const message = error.response?.data || 'Login failed: An unknown error occurred.';
            setErrorMessage(message);
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>SubAdmin Login</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
        </div>
    );
};

export default OpsLoginPage;
