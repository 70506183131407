import { LoadingButton } from '@mui/lab'
import { Button, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import { deleteAddressByIdAsync, selectAddressErrors, selectAddressStatus, updateAddressByIdAsync } from '../AddressSlice'

export const Address = ({ id, type, street, postalCode, country, phoneNumber, state, city, gstNumber, gstCompanyName, gstAddress }) => {

    const theme = useTheme()
    const dispatch = useDispatch()
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            type: type || '',
            street: street || '',
            postalCode: postalCode || '',
            country: country || '',
            phoneNumber: phoneNumber || '',
            state: state || '',
            city: city || '',
            gstNumber: gstNumber || '',
            gstCompanyName: gstCompanyName || '',
            gstAddress: gstAddress || ''
        }
    })
    const [edit, setEdit] = useState(false)
    const [open, setOpen] = useState(false);
    const status = useSelector(selectAddressStatus)
    const error = useSelector(selectAddressErrors)

    const is480 = useMediaQuery(theme.breakpoints.down(480))

    // Edit Profile Address //
    useEffect(() => {

        // setValue(key, editingAddress[key]);

        // Set the nested bussinessAddress fields separately
        setValue('gstNumber', gstNumber || '');
        setValue('gstCompanyName', gstCompanyName || '');
        setValue('gstAddress', gstAddress || '');

    }, [setValue]);

    const handleRemoveAddress = () => {
        dispatch(deleteAddressByIdAsync(id))
    }

    const handleUpdateAddress = (data) => {
        const profileAddressEdit = {
            type: data.type,
            street: data.street,
            country: data.country,
            phoneNumber: data.phoneNumber,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            email: data.email,
            bussinessAddress: {
                gstNumber: data.gstNumber,
                gstCompanyName: data.gstCompanyName,
                gstAddress: data.gstAddress
            }
        }
        const update = { ...profileAddressEdit, _id: id }
        setEdit(false)
        dispatch(updateAddressByIdAsync(update))
    }


    return (
        <Stack width={'100%'} p={is480 ? 0 : 1}>

            {/* address type */}
            <Stack color={'whitesmoke'} p={'.5rem'} borderRadius={'.2rem'} bgcolor={theme.palette.primary.main}>
                <Typography>{type?.toUpperCase()}</Typography>
            </Stack>

            {/* address details */}
            <Stack p={2} position={'relative'} flexDirection={'column'} rowGap={1} component={'form'} noValidate onSubmit={handleSubmit(handleUpdateAddress)}>

                {/* if the edit is true then this update from shows*/}
                {
                    edit ?
                        (
                            // update address form
                            <Stack rowGap={2}>

                                <Stack>
                                    <Typography gutterBottom>Type</Typography>
                                    <TextField

                                        {...register("type", {
                                            required: "Type is required", pattern: {
                                                value: /^[a-zA-Z\s]*$/,
                                                message: "Only letters are allowed",
                                            },
                                        })}
                                        sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                        error={!!errors.type}
                                        helperText={errors.type?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>Street</Typography>
                                    <TextField   {...register("street", {
                                        required: "Street is required", minLength: { value: 1, message: "Street must be at least 1 characters" },
                                        maxLength: { value: 6, message: "Street must not exceed 6 characters" }
                                    })}
                                        sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                        error={!!errors.street}
                                        helperText={errors.street?.message}
                                    />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>Postal Code</Typography>
                                    <TextField type='number'  {...register("postalCode", {
                                        required: "Postal Code is required", minLength: { value: 6, message: "Postal Code must be at least 6 characters" },
                                        maxLength: { value: 6, message: "Postal Code must not exceed 6 characters" }
                                    })}
                                        sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                        error={!!errors.postalCode}
                                        helperText={errors.postalCode?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>Country</Typography>
                                    <TextField type='text' {...register("country", {
                                        required: "Country is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                        sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                        error={!!errors.country}
                                        helperText={errors.country?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>Phone Number</Typography>
                                    <TextField type='number' {...register("phoneNumber", {
                                        required: "Phone number is required",
                                        pattern: {
                                            value: /^\d+$/,
                                            message: "Phone number must be numeric value"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Phone number must be at least 10 digits"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Phone number maximum 10 digits"
                                        }
                                    })}
                                        sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                        error={!!errors.phoneNumber}
                                        helperText={errors.phoneNumber?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>State</Typography>
                                    <TextField type='text' {...register("state", {
                                        required: "State is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                        sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                        error={!!errors.state}
                                        helperText={errors.state?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>City</Typography>
                                    <TextField type='text'  {...register("city", {
                                        required: "City is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                        sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                        error={!!errors.city}
                                        helperText={errors.city?.message} />
                                </Stack>
                                <Stack>
                                    <Typography gutterBottom>GST Number</Typography>
                                    <TextField {...register("gstNumber", {
                                        required: false, value: gstNumber, pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Kindly provide me correct GST Number",
                                        }
                                    })} error={!!errors.gstNumber}

                                        helperText={errors?.gstNumber?.message} />
                                </Stack>
                                <Stack>
                                    <Typography gutterBottom>Company Name</Typography>
                                    <TextField type='text' {...register("gstCompanyName", {
                                        required: false, value: gstCompanyName, pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Company Name cannot consist of only numbers.",
                                        },
                                    })} error={!!errors.gstCompanyName}
                                        helperText={errors?.gstCompanyName?.message} />
                                </Stack>
                                <Stack>
                                    <Typography gutterBottom>Company Address</Typography>
                                    <TextField {...register("gstAddress", {
                                        required: false, value: gstAddress, pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Company address cannot consist of only numbers.",
                                        },
                                    })} error={!!errors.gstAddress}

                                        helperText={errors?.gstAddress?.message} />
                                </Stack>
                            </Stack>
                        ) : (
                            <>
                                <Typography>Street - {street}</Typography>
                                <Typography>Postal Code- {postalCode}</Typography>
                                <Typography>Country - {country}</Typography>
                                <Typography>Phone Number - {phoneNumber}</Typography>
                                <Typography>State - {state}</Typography>
                                <Typography>City - {city}</Typography>
                                <Typography >GST Number - {gstNumber}</Typography>
                                <Typography >Company Name - {gstCompanyName}</Typography>
                                <Typography >Company Address - {gstAddress}</Typography>

                            </>
                        )
                }

                {/* action buttons */}
                <Stack position={is480 ? "static" : edit ? "static" : 'absolute'} bottom={4} right={4} mt={is480 ? 2 : 4} flexDirection={'row'} alignSelf={'flex-end'} columnGap={1}>

                    {/* if edit is true, then save changes button is shown instead of edit*/}
                    {
                        edit ? (<LoadingButton loading={status === 'pending'} size='small' type='submit' variant='contained'>Save Changes</LoadingButton>
                        ) : (<Button size='small' onClick={() => setEdit(true)} variant='contained'>Edit</Button>)
                    }

                    {/* if edit is true then cancel button is shown instead of remove */}
                    {
                        edit ? (
                            <Button size='small' onClick={() => { setEdit(false); reset() }} variant='outlined' color='error'>Cancel</Button>
                        ) : (
                            <LoadingButton loading={status === 'pending'} size='small' onClick={handleRemoveAddress} variant='outlined' color='error' >Remove</LoadingButton>
                        )
                    }
                </Stack>
            </Stack>

        </Stack>
    )
}
