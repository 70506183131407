import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderByUserIdAsync, resetOrderFetchStatus, selectOrderFetchStatus, selectOrders } from '../OrderSlice'
import { selectLoggedInUser } from '../../auth/AuthSlice'
import { Button, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme, Modal, Box, TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import { addToCartAsync, resetCartItemAddStatus, selectCartItemAddStatus, selectCartItems } from '../../cart/CartSlice'
import Lottie from 'lottie-react'
import { loadingAnimation, noOrdersAnimation } from '../../../assets'
import { toast } from 'react-toastify'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close';
import { fetchChatMessages, addMessage, createChat } from '../../../features/chat/chatApi';
import { NatsManager } from '../../../classes/natsManager';
import ModalChangeAddress from './ModalChangeAddress'
import { CustomStorageManager } from '../../../classes/storageManager'
export const UserOrders = () => {

    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false);
    const [openHelpModal, setOpenHelpModal] = useState(false)
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userLoginData, setUserLoginData] = useState()
    const loggedInUser = useSelector(selectLoggedInUser)
    const orders = useSelector(selectOrders)
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [orderAddressData, setOrderAddressData] = useState()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [orderId, setOrderId] = useState()
    const cartItems = useSelector(selectCartItems)
    const orderFetchStatus = useSelector(selectOrderFetchStatus)
    const theme = useTheme()
    const is1200 = useMediaQuery(theme.breakpoints.down("1200"))
    const is768 = useMediaQuery(theme.breakpoints.down("768"))
    const is660 = useMediaQuery(theme.breakpoints.down(660))
    const is480 = useMediaQuery(theme.breakpoints.down("480"))

    const cartItemAddStatus = useSelector(selectCartItemAddStatus)
    useEffect(() => {
        // Update screen width on window resize
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Determine styles based on screen width
    const modalStyles = {
        width: screenWidth <= 768 ? "90%" : screenWidth <= 1024 ? "75%" : "450px",  
        height: screenWidth <= 768 ? "80vh" : screenWidth <= 1024 ? "70vh" : "500px",
        top: screenWidth <= 768 ? "10%" : "55%",
        right: screenWidth <= 768 ? "0" : "20px", 
        left: screenWidth <= 768 ? "5%" : "auto",
        transform: screenWidth <= 768 ? "translateY(0)" : "translateY(-50%)",
        padding: "10px",
        backgroundColor: "white",
        borderRadius: "2px",
        border: "1px solid black",
        display: "flex",
        flexDirection: "column",
        position: "fixed",
    };
    

    const inputStyles = {
        width: screenWidth <= 768 ? "60%" : "70%",
        padding: "0.5rem",
        marginRight: "1rem",
        border: "1px solid #ddd",
        borderRadius: "4px",
    };

    const buttonStyles = {
        padding: "0.5rem 1rem",
        backgroundColor: "#007bff",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: loading ? "not-allowed" : "pointer",
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
    }, [])

    useEffect(() => {
        dispatch(getOrderByUserIdAsync(loggedInUser?._id))
    }, [dispatch])


    useEffect(() => {

        if (cartItemAddStatus === 'fulfilled') {
            toast.success("Product added to cart")
        }
        else if (cartItemAddStatus === 'rejected') {
            toast.error('Error adding product to cart, please try again later')
        }
    }, [cartItemAddStatus])

    useEffect(() => {
        if (orderFetchStatus === 'rejected') {
            toast.error("Error fetching orders, please try again later")
        }
    }, [orderFetchStatus])

    useEffect(() => {
        return () => {
            dispatch(resetOrderFetchStatus())
            dispatch(resetCartItemAddStatus())
        }
    }, [])

    useEffect(() => {
        fetchChatMessagesData(orderId)
        subscribeToChat()

    }, [])

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    //  user data from localStorege ///
    useEffect(() => {
        const fetchLoginData = async () => {
            const data = await CustomStorageManager.fetch('userData');
            setUserLoginData(data)
        };
        fetchLoginData();
    }, []);

    const handleHelp = async (order) => {
        setOrderId(order._id)
        try {
            const payload = {
                customerId: userLoginData._id,
                orderDetails: order,
                customerData: userLoginData,
                orderId: order._id
            }
            await createChat(payload);
            setOpenHelpModal(true);
        } catch (error) {
            console.error("Error creating chat:", error);
        }
        setOpenHelpModal(true)
    };

    const handleCloseHelpModal = () => {
        setOpenHelpModal(false)
    }

    // Change order address     
    const handleChangeOrderAddress = (orderId, addressData) => {
        setSelectedOrderId(orderId);
        setOrderAddressData(addressData);
        setOpenModal(true)
    }

    // Send a new message
    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;
        const newMessageObject = {
            _id: `msg-${Date.now()}-${Math.random().toString(36).substring(2, 15)}`,
            orderId: orderId,
            messageType: 'text',
            messageData: newMessage,
            isOps: false,
            createdAt: new Date().toISOString(),
        };

        // Optimistically update UI
        setMessages((prevMessages) => [...prevMessages, newMessageObject]);
        setNewMessage('');
        try {
            setLoading(true);
            await addMessage({
                orderId: orderId,
                messageType: 'text',
                messageData: newMessage,
                isOps: false,
            });

            // Publish the message to the NATS subject
            const subject = `chat.${orderId}`; // Unique subject for the chat
            await NatsManager.send(subject, JSON.stringify(newMessageObject)); // Publish the new message to NATS

        } catch (error) {
            console.error("Error sending message:", error);
            // Revert optimistic update if needed
            setMessages((prevMessages) => prevMessages.filter((msg) => msg._id !== newMessageObject._id));
        } finally {
            setLoading(false);
        }
    };

    // Fetch messages for a specific chat
    const fetchChatMessagesData = async (orderId) => {
        try {
            const response = await fetchChatMessages({ orderId });
            setMessages(response); // Store messages
        } catch (error) {
            console.error("Error fetching chat messages:", error);
        }
    };

    // Subscribe to the specific chat's subject
    const subscribeToChat = async () => {
        const subject = `chat.${orderId}`;
        NatsManager.subscribe(subject, (message) => {
            message = JSON.parse(message);
            setMessages((prevMessages) => {
                const filteredMessages = prevMessages.filter((msg) => msg._id !== message._id);
                const updatedMessages = [...filteredMessages, message];
                return updatedMessages;
            });
        });

    };
    const handleAddToCart = (product) => {
        const item = { user: loggedInUser._id, product: product._id, quantity: 1 }
        dispatch(addToCartAsync(item))
    }

    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            {
                orderFetchStatus === 'Pending' ?
                    <Stack width={is480 ? 'auto' : '25rem'} height={'calc(100vh - 4rem)'} justifyContent={'center'} alignItems={'center'}>
                        <Lottie animationData={loadingAnimation} />
                    </Stack>
                    :
                    <Stack width={is1200 ? "auto" : "60rem"} p={is480 ? 2 : 4} mb={'5rem'}>

                        {/* heading and navigation */}
                        <Stack flexDirection={'row'} columnGap={2} >
                            {
                                !is480 && <motion.div whileHover={{ x: -5 }} style={{ alignSelf: "center" }}>
                                    <IconButton component={Link} to={"/"}><ArrowBackIcon fontSize='large' /></IconButton>
                                </motion.div>
                            }


                            <Stack rowGap={1} >
                                <Typography variant='h4' fontWeight={500}>Order History</Typography>
                                <Typography sx={{ wordWrap: "break-word" }} color={'text.secondary'}>Check the status of recent orders, manage returns, and discover similar products.</Typography>
                            </Stack>

                        </Stack>

                        {/* orders */}
                        <Stack mt={5} rowGap={5}>

                            {/* orders mapping */}
                            {

                                orders && orders.length > 0 ? (
                                    orders.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                        .map((order) => (
                                            <Stack p={is480 ? 0 : 2} component={is480 ? "" : Paper} elevation={1} rowGap={2} key={order._id}>
                                                {/* upper */}
                                                <Stack flexDirection={'row'} rowGap={'1rem'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                                    <Stack flexDirection={'row'} columnGap={4} rowGap={'1rem'} flexWrap={'wrap'}>
                                                        <Stack>
                                                            <Typography>Order Number</Typography>
                                                            <Typography color={'text.secondary'}>{order._id}</Typography>
                                                        </Stack>

                                                        <Stack>
                                                            <Typography>Date Placed</Typography>
                                                            <Typography color={'text.secondary'}>{new Date(order.createdAt).toDateString()}</Typography>
                                                        </Stack>

                                                        <Stack>
                                                            <Typography>Total Amount</Typography>
                                                            <Typography>₹{order.total}</Typography>
                                                        </Stack>
                                                    </Stack>

                                                    <Stack>
                                                        <Typography>Item: {order.item.length}</Typography>
                                                    </Stack>
                                                </Stack>

                                                {/* Order Address */}

                                                <Stack rowGap={2}>
                                                    {
                                                        // Ensure order.address exists before calling map()
                                                        order.address && order.address.length > 0 ? (
                                                            order.address.map((addressData, index) => (

                                                                <Stack key={index} mt={2} flexDirection={'row'} rowGap={is768 ? '2rem' : ''} columnGap={4} flexWrap={is768 ? "wrap" : "nowrap"}>
                                                                    <Stack rowGap={1} width={'100%'} flexGrow={1}>
                                                                        <Stack><Typography> <span style={{ fontWeight: '500' }}>Delivery Address</span></Typography></Stack>
                                                                        <Stack>
                                                                            <Typography>  <span style={{ fontWeight: '500' }}>{addressData.type}</span></Typography>
                                                                            <Typography>{addressData.street}, {addressData.city}- {addressData.postalCode},</Typography>
                                                                            <Typography>  {addressData.state}, {addressData.country} </Typography>
                                                                            <Typography>
                                                                                <span style={{ fontWeight: '500' }}>Phone number </span>{addressData.phoneNumber}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </Stack>
                                                                    {
                                                                        order.status === "Pending" && (

                                                                            <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" width="100%" mt={-5}>
                                                                                <Button size='small' variant='outlined' onClick={() => handleChangeOrderAddress(order._id, addressData)}>
                                                                                    Change Address
                                                                                </Button>
                                                                            </Stack>
                                                                        )
                                                                    }

                                                                </Stack>



                                                            ))
                                                        ) : (
                                                            <Typography>No address</Typography>
                                                        )
                                                    }
                                                </Stack>
                                                {/* Open Modal */}

                                                <ModalChangeAddress
                                                    openModal={openModal}
                                                    handleCloseModal={handleCloseModal}
                                                    setOpenModal={setOpenModal}
                                                    orderAddressData={orderAddressData}
                                                    selectedOrderId={selectedOrderId}
                                                />


                                                {/* middle */}
                                                <Stack rowGap={2}>
                                                    {
                                                        // Ensure order.item exists before calling map()
                                                        order.item && order.item.length > 0 ? (
                                                            order.item.map((product) => (
                                                                <Stack mt={2} flexDirection={'row'} rowGap={is768 ? '2rem' : ''} columnGap={4} flexWrap={is768 ? "wrap" : "nowrap"} key={product.product._id}>
                                                                    <Stack>
                                                                        <img style={{ width: "100%", aspectRatio: is480 ? 3 / 2 : 1 / 1, objectFit: "contain" }} src={product.product.images[0]} alt={product.product.title} />
                                                                    </Stack>

                                                                    <Stack rowGap={1} width={'100%'}>
                                                                        <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Stack>
                                                                                <Typography variant='h6' fontSize={'1rem'} fontWeight={500}>{product.product.title}</Typography>
                                                                                <Typography variant='body1' fontSize={'.9rem'} color={'text.secondary'}>{product.product.brand.name}</Typography>
                                                                                <Typography color={'text.secondary'} fontSize={'.9rem'}>Qty: {product.quantity}</Typography>
                                                                            </Stack>
                                                                            <Typography>₹{product.product.price}</Typography>
                                                                        </Stack>

                                                                        <Typography color={'text.secondary'}>{product.product.description}</Typography>

                                                                        <Stack mt={2} alignSelf={is480 ? "flex-start" : 'flex-end'} flexDirection={'row'} columnGap={2}>
                                                                            <Button size='small' variant='outlined' onClick={() => handleHelp(order)}>Help</Button>
                                                                            <Modal
                                                                                open={openHelpModal}
                                                                                aria-labelledby="edit-address-modal"
                                                                                aria-describedby="edit-address-modal-description"
                                                                                BackdropProps={{
                                                                                    style: {
                                                                                        backgroundColor: "transparent",
                                                                                    },
                                                                                }}
                                                                                disableScrollLock={true}
                                                                            >
                                                                                <div style={modalStyles}>
                                                                                    <CloseIcon
                                                                                        onClick={handleCloseHelpModal}
                                                                                        sx={{
                                                                                            position: "absolute",
                                                                                            top: "10px",
                                                                                            right: "10px",
                                                                                            cursor: "pointer",
                                                                                            "&:hover": {
                                                                                                color: "red",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                    {/* Chat Room */}
                                                                                    <div
                                                                                        style={{
                                                                                            flex: 2,
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            height: "100%",
                                                                                        }}
                                                                                    >
                                                                                        {/* Chat Header */}
                                                                                        <div style={{ padding: "1rem", borderBottom: "1px solid #ccc" }}>
                                                                                            <h3>Chat with: </h3>
                                                                                            <p>Order ID: {orderId} </p>
                                                                                        </div>

                                                                                        {/* Chat Messages */}
                                                                                        <div style={{ flex: 1, overflowY: "auto", padding: "1rem" }}>
                                                                                            {messages.length > 0 ? (
                                                                                                messages.map((msg, index) => (
                                                                                                    <div
                                                                                                        key={index}
                                                                                                        style={{
                                                                                                            marginBottom: "1rem",
                                                                                                            textAlign: !msg.isOps ? "right" : "left",
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: "inline-block",
                                                                                                                padding: "0.5rem 1rem",
                                                                                                                borderRadius: "15px",
                                                                                                                backgroundColor: !msg.isOps ? "#d9f7be" : "#e6f7ff",
                                                                                                            }}
                                                                                                        >
                                                                                                            <p style={{ margin: 0 }}>{msg.messageData}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : (
                                                                                                <p>No messages yet in this chat.</p>
                                                                                            )}
                                                                                        </div>

                                                                                        {/* Input for sending new messages */}
                                                                                        <div style={{ padding: "1rem", borderTop: "1px solid #ccc" }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                placeholder="Type your message..."
                                                                                                value={newMessage}
                                                                                                onChange={(e) => setNewMessage(e.target.value)}
                                                                                                style={inputStyles}
                                                                                                disabled={loading}
                                                                                            />
                                                                                            <button
                                                                                                onClick={handleSendMessage}
                                                                                                style={buttonStyles}
                                                                                                disabled={loading}
                                                                                            >
                                                                                                {loading ? "Sending..." : "Send"}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Modal>


                                                                            <Button size='small' component={Link} to={`/product-details/${product.product._id}`} variant='outlined'>View Product</Button>

                                                                            {
                                                                                cartItems.some((cartItem) => cartItem.product._id === product.product._id) ?
                                                                                    <Button size='small' variant='contained' component={Link} to={"/cart"}>Already in Cart</Button>
                                                                                    : <Button size='small' variant='contained' onClick={() => handleAddToCart(product.product)}>Buy Again</Button>
                                                                            }
                                                                        </Stack>

                                                                    </Stack>

                                                                </Stack>
                                                            ))
                                                        ) : (
                                                            <Typography>No items in this order</Typography>
                                                        )
                                                    }
                                                </Stack>

                                                {/* lower */}
                                                <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'}>
                                                    <Typography mb={2}>Status : {order.status}</Typography>
                                                </Stack>
                                            </Stack>
                                        ))
                                ) : (
                                    <Typography>No orders available</Typography> // Fallback message when no orders exist
                                )
                            }

                            {/* no orders animation */}
                            {
                                !orders.length &&
                                <Stack mt={is480 ? '2rem' : 0} mb={'7rem'} alignSelf={'center'} rowGap={2}>

                                    <Stack width={is660 ? "auto" : '30rem'} height={is660 ? "auto" : '30rem'}>
                                        <Lottie animationData={noOrdersAnimation} />
                                    </Stack>

                                    <Typography textAlign={'center'} alignSelf={'center'} variant='h6' >oh! Looks like you haven't been shopping lately</Typography>

                                </Stack>
                            }

                        </Stack>

                    </Stack>

            }

        </Stack>
    )
}
