import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route, RouterProvider, createBrowserRouter, createRoutesFromElements
} from "react-router-dom";
import { selectIsAuthChecked, selectLoggedInUser } from './features/auth/AuthSlice';
import { Logout } from './features/auth/components/Logout';
import { Protected } from './features/auth/components/Protected';
import { useAuthCheck } from "./hooks/useAuth/useAuthCheck";
import { useFetchLoggedInUserDetails } from "./hooks/useAuth/useFetchLoggedInUserDetails";
import { AddProductPage, AdminOrdersPage, CartPage, CheckoutPage, ForgotPasswordPage, HomePage, LoginPage, OrderSuccessPage, OtpVerificationPage, ProductDetailsPage, ProductUpdatePage, ResetPasswordPage, SignupPage, UserOrdersPage, UserProfilePage, WishlistPage } from './pages';
import { AdminDashboardPage } from './pages/AdminDashboardPage';
import { NotFoundPage } from './pages/NotFoundPage';
import SubAdminDashboardPage from './pages/subadmin/SubAdminDashboardPage';
import { AddOpsTeamPage } from './pages/AddOpsTeamPage';
import PrivacyPolicy from './features/footer/PrivacyPolicy';
import RefundPolicy from './features/footer/RefundPolicy';
import AboutUs from './features/footer/AboutUs';
import ShippingPolicy from './features/footer/ShippingPolicy';
import SubAdminLoginPage from './pages/subadmin/SubAdminLoginPage';
import OpsLoginPage from './pages/ops/opsLoginPage';
import OpsDashboardPage from './pages/ops/opsDashboardPage';
import PaymentPage from './pages/PaymentPage';
import OrderSuccess from './pages/OrderSuccess';
import { NatsManager } from './classes/natsManager';
import { ProtectedSubAdmin } from './pages/subadmin/ProtectedSubadmin';

import RegisterStatus from './pages/RegisterStatus';
import DeviceDetailsPage from "./pages/subadmin/DeviceDetailsPage";
import SearchDevices from "./pages/subadmin/SearchDevices";
import DeviceMonitorPage from "./pages/subadmin/deviceMonitorPage";
import SearchOlderLogs from './pages/subadmin/searchOlderLogs';

import GuestUserOrderPage from './pages/GuestUserOrderPage';

function App() {

  const isAuthChecked = useSelector(selectIsAuthChecked);
  const loggedInUser = useSelector(selectLoggedInUser);
  NatsManager.init();

  useAuthCheck();
  useFetchLoggedInUserDetails(loggedInUser);

  const routes = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* Public Routes */}
        <Route path='/signup' element={<SignupPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/verify-otp' element={<OtpVerificationPage />} />
        <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route path='/reset-password/:userId/:passwordResetToken' element={<ResetPasswordPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/shipping-policy' element={<ShippingPolicy />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/' element={<HomePage />} /> {/* HomePage accessible without login */}

        {/* Protected Routes */}
        <Route path='/logout' element={<Protected><Logout /></Protected>} />
        <Route path='/product-details/:id' element={<Protected><ProductDetailsPage /></Protected>} />
        <Route path='/cart' element={<Protected><CartPage /></Protected>} />
        <Route path='/profile' element={<Protected><UserProfilePage /></Protected>} />
        <Route path='/checkout' element={<Protected><CheckoutPage /></Protected>} />
        <Route path='/order-success/:id' element={<Protected><OrderSuccessPage /></Protected>} />
        <Route path='/orders' element={<Protected><UserOrdersPage /></Protected>} />
        <Route path='/wishlist' element={<Protected><WishlistPage /></Protected>} />
        <Route path='/order-success' element={<Protected><OrderSuccess /></Protected>} />

        <Route path='/register-status' element={<Protected><RegisterStatus /></Protected>}></Route>


        {/* Guest User Truck Order */}
        <Route path='/track-order' element={<GuestUserOrderPage />} />


        {/* Admin Routes */}
        {loggedInUser?.isAdmin && (
          <>
            <Route path='/admin/dashboard' element={<Protected><AdminDashboardPage /></Protected>} />
            <Route path='/admin/product-update/:id' element={<Protected><ProductUpdatePage /></Protected>} />
            <Route path='/admin/add-product' element={<Protected><AddProductPage /></Protected>} />
            <Route path='/admin/orders' element={<Protected><AdminOrdersPage /></Protected>} />
            {/* <Route path='*' element={<Navigate to={'/admin/dashboard'} />} /> */}
          </>
        )}

        {/* Sub-admin Routes - No condition, open for everyone */}
        <Route path='/subadmin/login' element={<SubAdminLoginPage />} />

        <Route path='/subadmin/dashboard' element={<ProtectedSubAdmin><SubAdminDashboardPage /></ProtectedSubAdmin>} />

        <Route path="/device-details" element={<DeviceDetailsPage />} />
        <Route path="/search-devices" element={<SearchDevices />} />

        <Route path='/subadmin/add-ops-team' element={<Protected><AddOpsTeamPage /></Protected>} />
        <Route path="/device-monitor/:macId" element={<DeviceMonitorPage />} />
        <Route path="/device-search/:macId" element={<SearchOlderLogs />} />
        {/* <Route path='*' element={<Navigate to={'/subadmin/dashboard'} />} /> */}


        {/* ops Routes -  open for internal Team */}
        <Route path='/ops/login' element={<OpsLoginPage />} />
        <Route path='/ops/dashboard' element={<OpsDashboardPage />} />


        {/* Catch-all for Non-admin, Non-subadmin Routes */}
        {!(loggedInUser?.isAdmin) && (
          <Route path='*' element={<NotFoundPage />} />
        )}

        <Route path='/payment_page' element={<PaymentPage />} />
      </>
    )
  );

  return isAuthChecked ? <RouterProvider router={routes} /> : null;
}

export default App;