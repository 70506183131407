import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOrderAsync, initialpaymentAsync, selectCurrentOrder } from "../features/order/OrderSlice";
import { useLocation, useNavigate } from "react-router-dom";
import FormSubmitter from "../features/checkout/FormSubmitter";

const PaymentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const payload = location.state ? location.state.payload : null;
  const order = location.state ? location.state.order : null;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    const handlePayment = async () => {
      if (payload) {
        const response = await dispatch(initialpaymentAsync(payload)).unwrap();

        setInitialData(response)

      } else {
        setError("No payload found");
        setLoading(false);
      }
    };

    handlePayment();
  }, [payload, dispatch]);

  

  return (

    <div>
{/* <h1>dhasdhdh</h1> */}
      {initialData ? (
        <FormSubmitter
          gatewayURL={initialData.gatewayURL}
          EncData={initialData.EncData}
          data={initialData.data}
        />
      ) : (
        <p>Loading payment form...</p>
      )}
    </div>

  );
};

export default PaymentPage;