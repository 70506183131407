// features/subadmin/opsApi.js
import { CustomStorageManager } from '../../classes/storageManager';
import { axiosi } from '../../config/axios'; 

// Function for ops login
export const loginOps = async (credentials) => {
    try {
        const res = await axiosi.post('https://orginv8.in:8443/ops/login', credentials);
        await CustomStorageManager.store('opsToken', res.data.token);
        return res.data; // Return data for further use
    } catch (error) {
        const errorMessage = error.response ? error.response.data : 'Network Error';
        throw errorMessage; // Return the error message
    }
};
