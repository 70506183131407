import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    Box,
    Typography,
    Card,
    CardContent,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from '@mui/material';
import axios from 'axios'; // For making HTTP requests
import * as XLSX from 'xlsx'; // For parsing Excel files
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // Icon for upload

const SearchOptions = {
    keys: ['name', 'email'],
    threshold: 0.3,
    shouldSort: true,
};
//  const[isActive, setActive] =  useState("false") 


const SaleUpload = ({ subAdminData }) => {
    const [searchStr, setSearchStr] = useState('');
    const [searchRes, setSearchRes] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // For add user dialog
    const [newUser, setNewUser] = useState({ name: '', email: '', mobileNumber: '' });
    const [file, setFile] = useState(null); // State for the uploaded file
    const workerRef = useRef(null);
    const token = localStorage.getItem("opsToken");
    console.log(token);


    useEffect(() => {
        workerRef.current = new Worker(new URL('./searchWorker.js', import.meta.url));

        workerRef.current.onmessage = (e) => {
            setSearchRes(e.data);
        };

        return () => {
            workerRef.current.terminate();
        };
    }, []);

    const handleSearch = (value) => {
        workerRef.current.postMessage({
            data: subAdminData,
            options: SearchOptions,
            searchStr: value,
        });
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchStr(value);
        handleSearch(value);
    };

    const clearSearch = () => {
        setSearchStr('');
        setSearchRes([]);
    };

    const handleSelect = (user) => {
        setSelectedUser(user);
    };

    const handleAddUser = () => {
        setOpenDialog(true);
    };

    const handleSaveUser = async () => {
        // Prepare new user data for submission
        const userData = {
            name: newUser.name,
            email: newUser.email,
            mobileNumber: newUser.mobileNumber,
            devices: [], // You can assign devices as needed
        };

        try {
            // Make the POST request to the backend to add the new user (SubAdmin)
            const response = await axios.post('https://orginv8.in:8443/subadmin/signup', userData);

            if (response.status === 201) {
                // Successfully created the new user, close dialog and reset form
                setSelectedUser(response.data); // Optionally set the newly created user as selected
                setOpenDialog(false);
                setNewUser({ name: '', email: '', mobileNumber: '' }); // Clear input fields
                setSearchStr('');
                setSearchRes([]); // Optionally clear the search results
            }
        } catch (error) {
            console.error('Error creating new user:', error);
            // Handle the error (e.g., show a notification or alert)
        }
    };

    // Handle file upload
    const handleFileUpload = async (event) => {
        const selectedFile = event.target.files[0];

        if (!selectedFile || selectedFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            alert('Please upload a valid Excel file.');
            return;
        }

        const isConfirmed = window.confirm('Are you sure you want to upload this file?');
        if (!isConfirmed) {
            event.target.value = ''; // Reset input field
            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);


                const usersData = {}; // Object to group users by email

                jsonData.forEach(row => {
                    const {
                        "Email": email,
                        "Mac Id": macId,
                        "Order Id": orderId,
                        "Device Name": name,
                        "Manufarure Date": mfgDate,
                        "Expire Date": expireDate
                    } = row;

                    if (!email || !macId || !name || !mfgDate || !expireDate || !orderId) {
                        console.warn('Skipping row due to missing data:', row);
                        return;
                    }

                    // Convert date strings to ISO format
                    const formattedMfgDate = new Date(mfgDate).toISOString();
                    const formattedExpireDate = new Date(expireDate).toISOString();

                    // If user doesn't exist, initialize user entry
                    if (!usersData[email]) {
                        usersData[email] = {
                            subadminId: token,  // Replace with actual subadminId
                            orderId,
                            devices: []
                        };
                    }

                    // Add device details to the user’s devices array
                    usersData[email].devices.push({
                        macId,
                        name,
                        expireDate: formattedExpireDate,
                        mfgDate: formattedMfgDate
                    });
                });

                // Prepare data in the required format
                const formattedData = Object.values(usersData);
                // Send data to backend
                await uploadSaleData(formattedData);

                // Process user accounts
                for (const email in usersData) {
                    const user = usersData[email];

                    try {
                        const userExists = await checkIfUserExists(email);

                        if (userExists) {
                            await addMacIdsToUser(email, user.devices);
                        } else {
                            await createUser(user);
                        }
                    } catch (error) {
                        console.error(`Error processing user ${email}:`, error);
                    }
                }

                alert('File processed successfully.');
            } catch (error) {
                console.error('Error processing file:', error);
                alert('An error occurred while processing the file.');
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };


    // Check if user exists
    const checkIfUserExists = async (email) => {
        try {
            const response = await fetch(`http://localhost:8081/subadmin/checkUser?email=${email}`);
            const result = await response.json();
            console.log("result===", result)
            return result.exists; // Assumes the API returns `{ exists: true/false }`
        } catch (error) {
            console.error('Error checking if user exists:', error);
            return false;
        }
    };

    // Create a new user
    const createUser = async (user) => {
        try {
            const response = await fetch('https://orginv8.in:8443/subadmin/signup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(user)
            });
            const result = await response.json();
            console.log('User created:', result);
            return result
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    // Add macIds to an existing user
    const addMacIdsToUser = async (email, macIds) => {
        try {
            const response = await fetch('https://orginv8.in:8443/subadmin/addMacIdToDevices', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, macIds })
            });
            const result = await response.json();
            console.log('MacIds added to user:', result);
        } catch (error) {
            console.error('Error adding macIds to user:', error);
        }
    };
    // Upload sale data
    const uploadSaleData = async (usersData) => {
        try {
            for (const user of usersData) {
                const payload = {
                    subadminId: user.subadminId,
                    orderId: user.orderId,
                    devices: user.devices.map(device => ({
                        macId: device.macId,
                        name: device.name,
                        expireDate: new Date(device.expireDate).toISOString(),  
                        mfgDate: new Date(device.mfgDate).toISOString() 
                    }))
                };
                const response = await fetch('http://localhost:8081/sales/addSale', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload)
                });

                const result = await response.json();
                console.log("Sale added:", result);
            }
        } catch (error) {
            console.error('Error uploading sale data:', error);
        }
    };


    return (
        <Box
            sx={{
                maxWidth: 1200,
                margin: '0 auto',
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: '#fff',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                display: 'flex',
                gap: '20px',
            }}
        >
            {/* Left Section: Search */}
            <Box sx={{ flex: 1 }}>
                <Typography variant="h5" gutterBottom textAlign="center" color="primary">
                    User Management
                </Typography>

                {/* Search Section */}
                <Box sx={{ marginBottom: '20px' }}>
                    <TextField
                        label="Search by Name or Email"
                        variant="outlined"
                        fullWidth
                        value={searchStr}
                        onChange={handleChange}
                        sx={{ marginBottom: '10px' }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={clearSearch}
                            sx={{ width: '48%' }}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleAddUser}
                            sx={{ width: '48%' }}
                        >
                            Add User
                        </Button>
                    </Box>
                </Box>

                <Divider sx={{ marginBottom: '20px' }} />

                {/* Search Results */}
                <Typography variant="h6" gutterBottom>
                    Results
                </Typography>
                <List
                    sx={{
                        maxHeight: 200,
                        overflowY: 'auto',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: 0,
                    }}
                >
                    {searchRes.length > 0 ? (
                        searchRes.slice(0, 10).map((res, index) => (
                            <ListItem
                                key={index}
                                button
                                onClick={() => handleSelect(res.item)}
                                divider
                                sx={{
                                    padding: '10px',
                                }}
                            >
                                <ListItemText
                                    primary={res?.item?.name}
                                    secondary={res?.item?.email}
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                />
                            </ListItem>
                        ))
                    ) : (
                        <Typography textAlign="center" color="textSecondary" sx={{ padding: '10px' }}>
                            No results found
                        </Typography>
                    )}
                </List>
            </Box>

            {/* Right Section: Selected User Details */}
            <Box sx={{ flex: 1 }}>
                {selectedUser ? (
                    <Card
                        sx={{
                            borderRadius: '8px',
                            backgroundColor: '#f9f9f9',
                            padding: '20px',
                        }}
                    >
                        <Typography variant="h6" color="primary" gutterBottom>
                            Selected User Details
                        </Typography>
                        <Typography>
                            <strong>Name:</strong> {selectedUser.name}
                        </Typography>
                        <Typography>
                            <strong>Email:</strong> {selectedUser.email}
                        </Typography>
                        <Typography>
                            <strong>Mobile:</strong> {selectedUser.mobileNumber}
                        </Typography>
                        <Typography>
                            <strong>Verified:</strong> {selectedUser.isVerified ? 'Yes' : 'No'}
                        </Typography>
                        <Typography>
                            <strong>Devices:</strong>
                            <List dense>
                                {selectedUser.devices.map((device, idx) => (
                                    <ListItem key={idx} sx={{ padding: 0 }}>
                                        <ListItemText primary={device} />
                                    </ListItem>
                                ))}
                            </List>
                        </Typography>
                    </Card>
                ) : (
                    <Typography variant="body1" color="textSecondary" textAlign="center">
                        No user selected. Select a user to view details.
                    </Typography>
                )}

                {/* File Upload Section */}

                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6" gutterBottom>
                        Upload Excel for User
                    </Typography>
                    <input
                        type="file"
                        accept=".xlsx,.xls"
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        id="file-upload"
                    />
                    <label htmlFor="file-upload">
                        <Button
                            variant="contained"
                            component="span"
                            color="primary"
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload Excel
                        </Button>
                    </label>
                </Box>

            </Box>

            {/* Add User Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={newUser.name}
                        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                        sx={{ marginBottom: '10px' }}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        sx={{ marginBottom: '10px' }}
                    />
                    <TextField
                        label="Mobile Number"
                        variant="outlined"
                        fullWidth
                        value={newUser.mobileNumber}
                        onChange={(e) => setNewUser({ ...newUser, mobileNumber: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveUser} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SaleUpload;
