import { Avatar, Button, Paper, Stack, Typography, useTheme, TextField, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserInfo } from '../UserSlice'
import { addAddressAsync, resetAddressAddStatus, resetAddressDeleteStatus, resetAddressUpdateStatus, selectAddressAddStatus, selectAddressDeleteStatus, selectAddressErrors, selectAddressStatus, selectAddressUpdateStatus, selectAddresses } from '../../address/AddressSlice'
import { Address } from '../../address/components/Address'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { logoutAsync } from '../../auth/AuthSlice'
import { axiosi } from '../../../config/axios'
import { CustomStorageManager } from '../../../classes/storageManager'
import { deleteuseraccount } from '../../auth/AuthApi'

export const UserProfile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm()
    const status = useSelector(selectAddressStatus)
    const userInfo = useSelector(selectUserInfo)
    const addresses = useSelector(selectAddresses)
    const theme = useTheme()
    const [addAddress, setAddAddress] = useState(false)
    const addressAddStatus = useSelector(selectAddressAddStatus)
    const addressUpdateStatus = useSelector(selectAddressUpdateStatus)
    const addressDeleteStatus = useSelector(selectAddressDeleteStatus)
 

    const is900 = useMediaQuery(theme.breakpoints.down(900))
    const is480 = useMediaQuery(theme.breakpoints.down(480))

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
    }, [])


    useEffect(() => {
        if (addressAddStatus === 'fulfilled') {
            toast.success("Address added")
        }
        else if (addressAddStatus === 'rejected') {
            toast.error("Error adding address, please try again later")
        }
    }, [addressAddStatus])

    useEffect(() => {

        if (addressUpdateStatus === 'fulfilled') {
            toast.success("Address updated")
        }
        else if (addressUpdateStatus === 'rejected') {
            toast.error("Error updating address, please try again later")
        }
    }, [addressUpdateStatus])

    useEffect(() => {

        if (addressDeleteStatus === 'fulfilled') {
            toast.success("Address deleted")
        }
        else if (addressDeleteStatus === 'rejected') {
            toast.error("Error deleting address, please try again later")
        }
    }, [addressDeleteStatus])

    useEffect(() => {
        return () => {
            dispatch(resetAddressAddStatus())
            dispatch(resetAddressUpdateStatus())
            dispatch(resetAddressDeleteStatus())
        }
    }, [])



    const handleAddAddress = (data) => {
        const addressData = {
            type: data.type,
            street: data.street,
            country: data.country,
            phoneNumber: data.phoneNumber,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            email: data.email,
            bussinessAddress: {
                gstNumber: data.gstNumber,
                gstCompanyName: data.gstCompanyName,
                gstAddress: data.gstAddress
            }
        }
        const address = { ...addressData, user: userInfo._id }
        dispatch(addAddressAsync(address))
        setAddAddress(false)
        reset()
    }
   
    const handleDeleteAccount = async () => {
        const payload = {
            email: userInfo.email
        };
        const response = await deleteuseraccount(payload);
         if (response.status === 200) {
            toast.success(response.data.message);
            dispatch(logoutAsync())
            navigate('/');
        } else {
            toast.error("Error deleting account. Please try again.");
        }
    };



    return (
        <Stack height={'calc(100vh - 4rem)'} justifyContent={'flex-start'} alignItems={'center'}>

            <Stack component={is480 ? '' : Paper} elevation={1} width={is900 ? '100%' : "50rem"} p={2} mt={is480 ? 0 : 5} rowGap={2}>

                {/* user details - [name ,email ] */}
                <Stack bgcolor={theme.palette.primary.light} color={theme.palette.primary.main} p={2} rowGap={1} borderRadius={'.6rem'} justifyContent={'center'} alignItems={'center'}>
                    <Avatar src='none' alt={userInfo?.name} sx={{ width: 70, height: 70 }}></Avatar>
                    <Typography>{userInfo?.name}</Typography>
                    <Typography>{userInfo?.email}</Typography>
                </Stack>


                {/* address section */}
                <Stack justifyContent={'center'} alignItems={'center'} rowGap={3}>


                    {/* heading and add button */}
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} columnGap={1}>
                        <Typography variant='h6' fontWeight={400}>Manage Addresses</Typography>
                        <Button onClick={() => setAddAddress(true)} size={is480 ? 'small' : ""} variant='contained'>Add</Button>
                    </Stack>

                    {/* add address form - state dependent*/}
                    {
                        addAddress ? (
                            <Stack width={'100%'} component={'form'} noValidate onSubmit={handleSubmit(handleAddAddress)} rowGap={2}>

                                <Stack>
                                    <Typography gutterBottom>Type</Typography>
                                    <TextField

                                        placeholder='Eg. Home, Buisness'  {...register("type", {
                                            required: "Type is required", pattern: {
                                                value: /^[a-zA-Z\s]*$/,
                                                message: "Only letters are allowed",
                                            },
                                        })}
                                        error={!!errors.type}
                                        helperText={errors?.type?.message} />
                                </Stack>


                                <Stack>
                                    <Typography gutterBottom>Street</Typography>
                                    <TextField    {...register("street", {
                                        required: "Street is required",

                                        minLength: {
                                            value: 1,
                                            message: "Street number must be at least 1 digits"
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: "Street number maximum 6 digits"
                                        }
                                    })}
                                        error={!!errors.street}
                                        helperText={errors?.street?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>Postal Code</Typography>
                                    <TextField type='number'   {...register("postalCode", {
                                        required: "Postal code is required",
                                        minLength: {
                                            value: 6,
                                            message: "Postal code must be at least 6 digits"
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: "Postal code must be at most 6 digits"
                                        }
                                    })}
                                        error={!!errors.postalCode}
                                        helperText={errors?.postalCode?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>Country</Typography>
                                    <TextField    {...register("country", {
                                        required: "Country is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                        error={!!errors.country}
                                        helperText={errors?.country?.message}
                                    />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>Phone Number</Typography>
                                    <TextField type='number'   {...register("phoneNumber", {
                                        required: "Phone number is required",
                                        pattern: {
                                            value: /^\d+$/,
                                            message: "Phone number must be numeric value"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Phone number must be at least 10 digits"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Phone number maximum 10 digits"
                                        }
                                    })}
                                        error={!!errors.phoneNumber}
                                        helperText={errors?.phoneNumber?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>State</Typography>
                                    <TextField type='text' {...register("state", {
                                        required: "State is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                        error={!!errors.state}
                                        helperText={errors?.state?.message} />
                                </Stack>

                                <Stack>
                                    <Typography gutterBottom>City</Typography>
                                    <TextField type='text' {...register("city", {
                                        required: "City is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                        error={!!errors.city}
                                        helperText={errors?.city?.message} />
                                </Stack>

                                <Stack flexDirection={'row'}>
                                    <Stack width={'100%'}>
                                        <Typography gutterBottom>GST Number(Optional)</Typography>
                                        <TextField type='text'  {...register("gstNumber", {
                                            required: false, pattern: {
                                                value: /^(?!\d+$).*/,
                                                message: "Kindly provide me correct GST Number",
                                            }
                                        })} error={!!errors.gstNumber}

                                            helperText={errors?.gstNumber?.message} />
                                    </Stack>
                                    <Stack width={'100%'}>
                                        <Typography gutterBottom>Company Name(Optional)</Typography>
                                        <TextField type='text' {...register("gstCompanyName", {
                                            required: false, pattern: {
                                                value: /^(?!\d+$).*/,
                                                message: "Company Name cannot consist of only numbers.",
                                            },
                                        })} error={!!errors.gstCompanyName}
                                            helperText={errors?.gstCompanyName?.message} />
                                    </Stack>
                                    <Stack width={'100%'}>
                                        <Typography gutterBottom>Business Address(Optional)</Typography>
                                        <TextField type='text' {...register("gstAddress", {
                                            required: false, pattern: {
                                                value: /^(?!\d+$).*/,
                                                message: "Company address cannot consist of only numbers.",
                                            },
                                        })} error={!!errors.gstAddress}

                                            helperText={errors?.gstAddress?.message} />
                                    </Stack>
                                </Stack>

                                <Stack flexDirection={'row'} alignSelf={'flex-end'} columnGap={is480 ? 1 : 2}>
                                    <LoadingButton loading={status === 'pending'} type='submit' size={is480 ? "small" : ""} variant='contained'>add</LoadingButton>
                                    <Button color='error' onClick={() => {
                                        setAddAddress(false);
                                        reset();
                                    }} variant={is480 ? "outlined" : "text"} size={is480 ? "small" : ""} >cancel</Button>
                                </Stack>
                            </Stack>
                        ) : ('')
                    }

                    {/* mapping on addresses here  */}
                    <Stack width={'100%'} rowGap={2}>
                        {
                            addresses.length > 0 ? (
                                addresses.map((address) => (
                                    <Address
                                        key={address._id} id={address._id} city={address.city} country={address.country} phoneNumber={address.phoneNumber} postalCode={address.postalCode} state={address.state} street={address.street} type={address.type}
                                        gstNumber={address.bussinessAddress.gstNumber}
                                        gstCompanyName={address.bussinessAddress.gstCompanyName}
                                        gstAddress={address.bussinessAddress.gstAddress}

                                    />
                                ))
                            ) : (
                                <Typography textAlign={'center'} mt={2} variant='body2'>You have no added addresses</Typography>
                            )
                        }
                    </Stack>

                </Stack>
                {/* Delete Account */}
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} columnGap={1}>
                    <Button onClick={handleDeleteAccount} size={is480 ? 'small' : ""} variant='contained'>Delete Account</Button>
                </Stack>

            </Stack>



        </Stack>
    )
}
