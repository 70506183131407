import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Chart } from "react-google-charts";
import "./DeviceDetails.css";

const DeviceMonitorPage = () => {
    const { macId } = useParams();
    const navigate = useNavigate();

    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newLimits, setNewLimits] = useState({})
    const [deviceStatus, setDeviceStatus] = useState({});
    const [restartButton, setRestartButton] = useState({});
    const [updateButtonStatusMap, setUpdateButtonStatusMap] = useState({});
    const [device, setDevice] = useState([]);

    useEffect(() => {
        let fetchInterval;

        const fetchDeviceData = async () => {
            try {
                const response = await fetch(`https://orginv8.in:8443/device/fetchDeviceByMacId/?id=${macId}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch device details");
                }
                const data = await response.json();
                console.log(data);
                setDevice(data[0]);
                setDevices(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDeviceData();
        fetchInterval = setInterval(fetchDeviceData, 3000);
        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
        };
    }, [macId]);

    const handleRestartDevice = async (macId) => {
        try {
            setRestartButton((prev) => ({
                ...prev,
                [macId]: {
                    value: "Restarting",
                    color: "blue"
                }
            }));

            const response = await fetch("https://orginv8.in:8443/device/restart", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ macId }),
            });

            const result = await response.json();
            console.log("Restart result:", result);

            if (result.success) {
                setRestartButton((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Restarted",
                        color: "green"
                    }
                }));
            } else {
                setRestartButton((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Failed",
                        color: "red"
                    }
                }));
            }
        } catch (err) {
            console.error("Error restarting device:", err);
        } finally {
            setTimeout(() => {
                setRestartButton((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Restart",
                        color: "orange"
                    }
                }));
            }, 3000);
        }
    };

    const handleNewLimitChange = (macId, capability, value) => {
        setNewLimits((prev) => ({
            ...prev,
            [macId]: {
                ...prev[macId],
                [capability]: value,
            },
        }));
    };

    const handleUpdateAllLimits = async (macId) => {
        const updatedLimits = newLimits[macId] || {};
        const device = devices.find((dev) => dev.macId === macId);

        if (!device) {
            alert("Device not found.");
            return;
        }
        const finalLimits = { ...device.limits, ...updatedLimits };

        try {
            setUpdateButtonStatusMap((prev) => ({
                ...prev,
                [macId]: {
                    value: "Loading",
                    color: "blue"
                }
            }));
            const response = await fetch("https://orginv8.in:8443/device/updateLimits", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ macId: macId, limits: finalLimits }),
            });
            if (!response.ok) {
                setUpdateButtonStatusMap((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Update failed",
                        color: "red"
                    }
                }));
            } else {
                setUpdateButtonStatusMap((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Update success",
                        color: "green"
                    }
                }));
            }

            const result = await response.json();
            console.log(`Updated limits for device ${macId}:`, result);

            setDevices((prevDevices) =>
                prevDevices.map((dev) =>
                    dev.macId === macId
                        ? {
                            ...dev,
                            limits: finalLimits,
                        }
                        : dev
                ));

            setNewLimits((prev) => ({
                ...prev,
                [macId]: {},
            }));
        } catch (err) {
            console.error(err);
            alert(`Error updating limits: ${err.message}`);
        } finally {
            setTimeout(() => {
                setUpdateButtonStatusMap((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Update",
                        color: "orange"
                    }
                }));
            }, 3000);
        }
    };

    const checkDeviceStatus = async (macId) => {
        try {
            setDeviceStatus((prev) => ({
                ...prev,
                [macId]: { value: "loading", color: "blue" },
            }));
            const response = await fetch("https://orginv8.in:8443/device/ping", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ macId }),
            });

            const result = await response.json();
            const status = result.message === 1 ? "Online" : "Offline";
            const color = result.message === 1 ? "green" : "red";
            // Set status temporarily
            setDeviceStatus((prev) => ({
                ...prev,
                [macId]: { value: status, color: color },
            }));

            // Clear status after 3 seconds
            setTimeout(() => {
                setDeviceStatus((prev) => {
                    const updated = { ...prev };
                    delete updated[macId];
                    return updated;
                });
            }, 3000);
        } catch (err) {
            console.error("Error pinging device:", err);
            setDeviceStatus((prev) => ({
                ...prev,
                [macId]: "Error",
            }));

            // Clear status after 3 seconds
            setTimeout(() => {
                setDeviceStatus((prev) => {
                    const updated = { ...prev };
                    delete updated[macId];
                    return updated;
                });
            }, 3000);
        }
    };
    const handleSearchOldLogs = (macId) => {
        //clearInterval(fetchInterval);
        navigate(`/device-search/${macId}`);
    }
    const renderDevicesTable = () => (
        <div className="details-container">
            <div className="parent">
                <div>
                    <h3>Device Details</h3>
                    <h4>{device.deviceModelno}</h4>
                    <p><strong>MAC ID:</strong> {device.macId}</p>
                    <p><strong>LAST UPDATED AT:</strong> {new Date(device.updatedAt).toLocaleString()}</p>
                </div>
                <div className="card-buttons">
                    <button
                        onClick={() => checkDeviceStatus(device.macId)}
                        className="check-status-button"
                        style={{
                            backgroundColor: deviceStatus[device.macId]
                                ? deviceStatus[device.macId].color
                                : "orange",
                        }}
                    >
                        {deviceStatus[device.macId]
                            ? deviceStatus[device.macId].value
                            : "Check Status"}
                    </button>
                    <button
                        onClick={() => handleUpdateAllLimits(device.macId)}
                        style={{
                            backgroundColor: updateButtonStatusMap[device.macId]
                                ? updateButtonStatusMap[device.macId].color
                                : "orange",
                        }}
                    >
                        {updateButtonStatusMap[device.macId]
                            ? updateButtonStatusMap[device.macId].value
                            : "Update Limits"}
                    </button>
                    <button
                        onClick={() => handleRestartDevice(device.macId)}
                        style={{
                            backgroundColor: restartButton[device.macId]
                                ? restartButton[device.macId].color
                                : "orange",
                        }}
                    >
                        {restartButton[device.macId]
                            ? restartButton[device.macId].value
                            : "Restart"}
                    </button>
                    <button onClick={() => handleSearchOldLogs(device.macId)}>
                        Search Older Logs
                    </button>
                </div>
            </div>

            <table className="capabilities-table">
                <thead>
                    <tr>
                        <th>Capability</th>
                        <th>Value</th>
                        <th>Limit</th>
                        <th>New Limit</th>
                    </tr>
                </thead>
                <tbody>
                    {device.selectedCapabilities
                        .map((capability) => capability.update)
                        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                        .slice(0, 1)
                        .map((update, index) =>
                            Object.entries(update).map(([key, value]) => (
                                <tr
                                    key={`${device.macId}-${index}-${key}`}
                                    className={
                                        device.limits?.[key] < value ? "highlight-row" : ""
                                    }
                                >
                                    <td>{key}</td>
                                    <td>{value}</td>
                                    <td>{device.limits?.[key] || "N/A"}</td>
                                    <td>
                                        <input
                                            type="number"
                                            className="new-limit-input"
                                            placeholder="Enter new limit"
                                            value={
                                                newLimits[device.macId]?.[key] ||
                                                ""
                                            }
                                            onChange={(e) =>
                                                handleNewLimitChange(
                                                    device.macId,
                                                    key,
                                                    e.target.value
                                                )
                                            }

                                        />
                                    </td>
                                </tr>
                            ))
                        )}
                </tbody>
            </table>

            <div className="graphs">
                {device.setKeys.map((key) => {
                    const data = [];
                    const finalList = {};

                    device.selectedCapabilities.forEach((capability) => {
                        const update = capability.update;
                        if (!finalList[key]) {
                            finalList[key] = [];
                        }
                        if (update[key]) {
                            finalList[key].push([
                                new Date(capability.updatedAt).toLocaleString(),
                                update[key],
                            ]);
                        }
                    });

                    if (finalList[key].length > 0) {
                        finalList[key].forEach((entry) => {
                            data.push(entry);
                        });

                        return (
                            <div
                                key={`${device.macId}-${key}-graph`}
                                className="graph-container"
                            >
                                <h5>{key} Graph</h5>
                                <Chart
                                    chartType="LineChart"
                                    data={[["Time", "Value"], ...data]}
                                    width="100%"
                                    height="400px"
                                    legendToggle
                                />
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
    if (loading) return <p>Loading device details...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!device) return <p>No device found.</p>;
    return (
        <div className="device-monitor-page">
            <button onClick={() => navigate(-1)} className="back-button">Go Back</button>
            {renderDevicesTable()}
        </div>
    );
};

export default DeviceMonitorPage;
