import React, { useState, useRef, useEffect } from 'react';
import "./opsDashboard.css";
import { QRCodeCanvas } from 'qrcode.react';
import { fetchSubAdmin } from '../../features/subadmin/subadminApi';
import SaleUpload from './components/saleUpload'; // Import the SaleUpload component
import QueryPage from './components/chat';
const OpsDashboardPage = () => {
  const [macId, setMacId] = useState('');
  const [qrLink, setQrLink] = useState('');
  const [selectedTab, setSelectedTab] = useState('Query');
  const qrRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subAdminData, setSubAdminData] = useState([]);
  // const [selectedTab, setActive] = useState('');

  const generateRandomMacId = () => {
    const timestamp = Date.now();
    return timestamp;
  };

  const fetchData = async () => {
    try {
      const data = await fetchSubAdmin();
      setSubAdminData(data.subadminData || []);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTab === "SaleUpload") {
      fetchData();
    }
  }, [selectedTab]);

  useEffect(() => {
    setMacId(generateRandomMacId());
  }, []);

  const createQr = () => {
    const baseUrl = 'https://orginv8.in:8443/device/registration';
    const finalLink = macId ? `${baseUrl}?macId=${encodeURIComponent(macId)}` : baseUrl;
    setQrLink(finalLink);
    setMacId(generateRandomMacId());
  };

  const downloadQrAsJpg = () => {
    const canvas = qrRef.current.querySelector('canvas');
    if (canvas) {
      const jpgUrl = canvas.toDataURL('image/jpeg');
      const downloadLink = document.createElement('a');
      downloadLink.href = jpgUrl;
      downloadLink.download = 'qr-code.jpg';
      downloadLink.click();
    }
  };

  const renderContent = () => {
    if (selectedTab === 'createQRCode') {
      return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h1>Ops Dashboard</h1>
          <div style={{ marginBottom: '20px' }}>
            <input
              type="text"
              placeholder="Enter MAC ID"
              value={macId}
              onChange={(e) => setMacId(e.target.value)}
              style={{ padding: '10px', width: '250px', fontSize: '16px' }}
            />
            <button
              onClick={createQr}
              style={{
                marginLeft: '10px',
                padding: '10px 20px',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              Generate QR
            </button>
          </div>
          {qrLink && (
            <div>
              <p><strong>Generated Link:</strong> {qrLink}</p>
              <br />
              <div ref={qrRef}>
                <QRCodeCanvas value={qrLink} size={200} />
              </div>
              <br />
              <button
                onClick={downloadQrAsJpg}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  backgroundColor: '#007BFF',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                }}
              >
                Download QR Code (JPG)
              </button>
            </div>
          )}
        </div>
      );
    } else if (selectedTab === 'SaleUpload') {
      return <SaleUpload subAdminData={subAdminData} />;
    } else if (selectedTab === 'Query') {
      return <QueryPage />
    }
  };

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <ul>
          <li onClick={() => setSelectedTab('createQRCode')}
            className={selectedTab === 'createQRCode' ? 'active' : ''}>Create QR code</li>
          <li onClick={() => setSelectedTab('SaleUpload')}
            className={selectedTab === 'SaleUpload' ? 'active' : ''}>Sale Upload</li>
          <li onClick={() => setSelectedTab('Query')}
            className={selectedTab === 'Query' ? 'active' : ''}>Query</li>
        </ul>

      </div>
      <div className="main-content">{renderContent()}</div>
    </div>
  );
};

export default OpsDashboardPage;



