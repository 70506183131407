import { connect, StringCodec } from 'nats.ws';

export class NatsManager {
    static natsConnection = null;
    static sc = StringCodec();

    // Initialize the NATS connection
    static async init() {
        try {
            this.natsConnection = await connect({
                servers: "wss://orginv8.in:440", // Replace with your NATS server address and WebSocket port
                token: "oillp-nats",  // Authorization token for access
            });
            console.log("NATS connection established");
        } catch (e) {
            console.error("Error connecting to NATS:", e);
        }
    }

    // Send a message to a specific subject
    static async send(subject, value) {
        try {
            if (!this.natsConnection) {
                console.error("NATS connection is not established. Please call init() first.");
                return false;
            }

            await this.natsConnection.publish(subject, this.sc.encode(value));
            console.log(`Message sent to ${subject}:`, value);
            return true;
        } catch (e) {
            console.error("Error sending message:", e);
            return false;
        }
    }

    // Subscribe to a subject and handle incoming messages
    static async subscribe(subject, callback) {
        try {
            if (!this.natsConnection) {
                console.error("NATS connection is not established. Please call init() first.");
                return;
            }

            const subscription = this.natsConnection.subscribe(subject);
            console.log(`Subscribed to subject: ${subject}`);

            // Continuously listen for messages on the subject
            (async () => {
                for await (const msg of subscription) {
                    const decodedMessage = this.sc.decode(msg.data);
                    console.log(`Received message from ${subject}:`, decodedMessage);
                    callback(decodedMessage);
                }
            })();
        } catch (e) {
            console.error(`Error subscribing to ${subject}:`, e);
        }
    }

    // Close the NATS connection
    static async close(){
        try {
            if (this.natsConnection) {
                await this.natsConnection.close();
                console.log("NATS connection closed.");
            } else {
                console.log("No active NATS connection.");
            }
        } catch (e) {
            console.error("Error closing NATS connection:", e);
        }
    }
}
