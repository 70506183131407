import { FormHelperText, Stack, TextField, Typography, Box, useTheme, useMediaQuery, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { ecommerceOutlookAnimation, shoppingBagAnimation } from '../../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab';
import { selectLoggedInUser, signupAsync, selectSignupStatus, selectSignupError, clearSignupError, resetSignupStatus } from '../AuthSlice'
import { toast } from 'react-toastify'
import { MotionConfig, motion } from 'framer-motion'
import { Home, Visibility, VisibilityOff } from '@mui/icons-material';

export const Signup = () => {
  const dispatch = useDispatch()
  const status = useSelector(selectSignupStatus)
  const error = useSelector(selectSignupError)
  const loggedInUser = useSelector(selectLoggedInUser)
  const { register, handleSubmit, reset, formState: { errors } } = useForm()
  const navigate = useNavigate()
  const theme = useTheme()
  const is900 = useMediaQuery(theme.breakpoints.down(900))
  const is480 = useMediaQuery(theme.breakpoints.down(480))
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Hide and show password
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);
  // handles user redirection
  useEffect(() => {
    if (loggedInUser && !loggedInUser?.isVerified) {
      navigate("/verify-otp")
    }
    else if (loggedInUser && loggedInUser._id == "66f2434c53bbe09c7c63f444") {
      navigate("/signup")
    }
  }, [loggedInUser, navigate])


  // handles signup error and toast them
  useEffect(() => {
    if (error) {
      toast.error(error.message)
    }
  }, [error])


  useEffect(() => {
    if (status === 'fullfilled') {
      toast.success("Welcome! Verify your email to start shopping on Original Innovation LLP.")
      reset()
    }
    return () => {
      dispatch(clearSignupError())
      dispatch(resetSignupStatus())
    }
  }, [status])

  // this function handles signup and dispatches the signup action with credentails that api requires
  const handleSignup = (data) => {
    const cred = { ...data }
    delete cred.confirmPassword
    dispatch(signupAsync(cred))
  }

  return (
    <Stack width={'100vw'} height={'100vh'} flexDirection={'row'} sx={{ overflowY: "hidden" }}>

      {
        !is900 &&

        <Stack bgcolor={'black'} flex={1} justifyContent={'center'} >
          <Lottie animationData={ecommerceOutlookAnimation} />
        </Stack>

      }

      <Stack flex={1} justifyContent={'center'} alignItems={'center'}>

        <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
          {/* Icon Stack */}

          <Stack direction="row" alignItems="center" columnGap={'.8rem'}>
            {/* Home Icon */}
            <Link to="/">
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Home sx={{ fontSize: 40, cursor: 'pointer', color: 'black' }} /> {/* Home icon */}
              </motion.div>
            </Link>

            {/* Text Stack */}
            <Stack sx={{ marginTop: '1rem' }}>
              <Typography variant="h6" noWrap component="a" href="/" sx={{
                mr: 2, display: { xs: 'none', md: 'flex' }, fontWeight: 700,
                letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none',
              }}>
                Original Innovation LLP
              </Typography>
              
              <Typography alignSelf={'flex-end'} color={'GrayText'} variant='body2'>
                - Innovate Anything
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack mt={4} spacing={2} width={is480 ? "95vw" : '28rem'} component={'form'} noValidate onSubmit={handleSubmit(handleSignup)}>

          <MotionConfig whileHover={{ y: -5 }}>

            <motion.div>
              <TextField fullWidth {...register("name", { required: "Username is required" })} placeholder='Username' />
              {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
            </motion.div>

            <motion.div>
              <TextField fullWidth {...register("email", { required: "Email is required", pattern: { value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g, message: "Enter a valid email" } })} placeholder='Email' />
              {errors.email && <FormHelperText error>{errors.email.message}</FormHelperText>}
            </motion.div>

            <motion.div>
              <TextField type={showPassword ? "text" : "password"} fullWidth {...register("password", { required: "Password is required", pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm, message: `at least 8 characters, must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number, Can contain special characters` } })}
                placeholder='Password' InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }} />
              {errors.password && <FormHelperText error>{errors.password.message}</FormHelperText>}
            </motion.div>

            <motion.div>
              <TextField type={showConfirmPassword ? "text" : "password"} fullWidth {...register("confirmPassword", { required: "Confirm Password is required", validate: (value, fromValues) => value === fromValues.password || "Passwords doesn't match" })}
                placeholder='Confirm Password' InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }} />
              {errors.confirmPassword && <FormHelperText error>{errors.confirmPassword.message}</FormHelperText>}
            </motion.div>

          </MotionConfig>

          <motion.div whileHover={{ scale: 1.020 }} whileTap={{ scale: 1 }}>
            <LoadingButton sx={{ height: '2.5rem' }} fullWidth loading={status === 'pending'} type='submit' variant='contained'>Signup</LoadingButton>
          </motion.div>

          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap-reverse'}>
            <MotionConfig whileHover={{ x: 2 }} whileTap={{ scale: 1.050 }}>
              <motion.div>
                <Typography mr={'1.5rem'} sx={{ textDecoration: "none", color: "text.primary" }} to={'/forgot-password'} component={Link}>Forgot password</Typography>
              </motion.div>

              <motion.div>
                <Typography sx={{ textDecoration: "none", color: "text.primary" }} to={'/login'} component={Link}>Already a member? <span style={{ color: theme.palette.primary.dark }}>Login</span></Typography>
              </motion.div>
            </MotionConfig>
          </Stack>

        </Stack>


      </Stack>
    </Stack>
  )
}
