// features/subadmin/deviceApi.js
import { MacroOffOutlined } from '@mui/icons-material';
import { axiosi } from '../../config/axios'; // Adjust the import as needed

export const fetchDevicesBySubAdminId = async (subAdminId) => {
    try {
        const res = await axiosi.post(`https://orginv8.in:8443/device/fetchDevicesBySubAdminId`, {
            "subAdminId": subAdminId  // Using params to send the subAdminId
        });// Adjust the URL
        return res.data; // Adjust based on your API response
    } catch (error) {
        throw error.response.data; // Handle error
    }
};
export const addSearchHistory = async (macId, subAdminId) => {
    try {
        const res = await axiosi.post(`https://orginv8.in:8443/device/addPreviousSearch`, {
            "subAdminId": subAdminId, // Using params to send the subAdminId,
            "macId": macId //macId of the device 
        });// Adjust the URL
        //return res.data.devices; // Adjust based on your API response
    } catch (e) {
        //throw error.response.data; // Handle error
    }
}