import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Chip, Stack, useMediaQuery, useTheme, Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../../user/UserSlice';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { selectCartItems } from '../../cart/CartSlice';
import { selectLoggedInUser } from '../../auth/AuthSlice';
import { selectWishlistItems } from '../../wishlist/WishlistSlice';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TuneIcon from '@mui/icons-material/Tune';
import { selectProductIsFilterOpen, toggleFilters } from '../../products/ProductSlice';
import logo from "../../../assets/images/logo.jpg"
import { useEffect } from 'react';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

export const Navbar = ({ isProductList = false }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const userInfo = useSelector(selectUserInfo);
  const cartItems = useSelector(selectCartItems); 
  console.log("cartItems===",cartItems)
  const loggedInUser = useSelector(selectLoggedInUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const is480 = useMediaQuery(theme.breakpoints.down(480));
  const wishlistItems = useSelector(selectWishlistItems);
  const isProductFilterOpen = useSelector(selectProductIsFilterOpen);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleToggleFilters = () => {
    dispatch(toggleFilters());
  };

  const settings = [
    { name: "Home", to: "/" },
    { name: 'Profile', to: loggedInUser?.isAdmin ? "/admin/profile" : "/profile" },
    { name: loggedInUser?.isAdmin ? 'Orders' : 'My orders', to: loggedInUser?.isAdmin ? "/admin/orders" : "/orders" },
    { name: 'Logout', to: "/logout" },
  ];

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "white", boxShadow: "none", color: "text.primary" }}>
      <Toolbar sx={{ p: 1, height: "4rem", display: "flex", justifyContent: "space-around" }}>

      <Typography
      variant="h6"
      noWrap
      component="a"
      href="/"
      sx={{
        mr: 2,
        display: 'flex',
        alignItems: 'center', 
        fontWeight: 700,
        letterSpacing: '.2rem',
        color: '#ff8533',
        textDecoration: 'none',
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap', 
        maxWidth: '100%', 
        '@media (max-width: 600px)': { 
          fontSize: '0.9rem',
          letterSpacing: '0.05rem',
        },
      }}
    >
      <img
        src={logo}
        alt="logo"
        style={{
          width: '45px',
          height: '45px',
          marginRight: '8px',
          marginTop: '-5px',
        }}
      />
      Original Innovation LLP
    </Typography>
        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} columnGap={2}>
          {/* Menu Icon for Mobile */}
          <IconButton onClick={toggleDrawer} sx={{ display: { xs: 'block', sm: 'none' } }}>
            <MenuIcon />
          </IconButton>

          {/* Desktop view */}
          <Stack flexDirection={'row'} alignItems={'center'} columnGap={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            {loggedInUser && loggedInUser._id !== process.env.REACT_APP_GUESTUSER_ID ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={userInfo?.name} src="null" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '37px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {loggedInUser?.isAdmin && (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography component={Link} color={'text.primary'} sx={{ textDecoration: "none" }} to="/admin/add-product" textAlign="center">Add new Product</Typography>
                    </MenuItem>
                  )}
                  {settings.map((setting) => (
                    <MenuItem key={setting.name} onClick={handleCloseUserMenu} component="a" href={setting.to}>
                      <Typography component="span" color={'text.primary'} sx={{ textDecoration: "none", width: "100%", textAlign: "center" }}>
                        {setting.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
                <Typography variant='h6' fontWeight={300}>
                  {userInfo?.name}
                </Typography>
              </>
            ) : (
              <>
                <Button variant='contained' onClick={() => navigate('/login')}>Login</Button>
                <Button variant='contained' onClick={() => navigate("/track-order")}>Track Order</Button>
              </>
            )}

            {cartItems?.length > 0 && (
              <Tooltip title='Cart'>
                <Badge badgeContent={cartItems.length} color='error'>
                  <IconButton onClick={() => navigate("/cart")}>
                    <ShoppingCartOutlinedIcon />
                  </IconButton>
                </Badge>
              </Tooltip>
            )}

            {!loggedInUser?.isAdmin && (
              <Tooltip title="Wishlist">
                <Badge badgeContent={wishlistItems?.length} color='error'>
                  <IconButton component={Link} to={"/wishlist"}>
                    <FavoriteBorderIcon />
                  </IconButton>
                </Badge>
              </Tooltip>
            )}
          </Stack>

        </Stack>

        {/* Mobile Drawer */}
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
        >
          <Stack sx={{ width: 250, padding: 2 }} spacing={2}>
            {loggedInUser && loggedInUser._id !== process.env.REACT_APP_GUESTUSER_ID ? (
              <>
                <Button variant="text" onClick={handleOpenUserMenu} sx={{ width: '100%' }}>
                  {userInfo?.name}
                </Button>
                <Menu
                  sx={{ mt: '37px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {loggedInUser?.isAdmin && (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography component={Link} color={'text.primary'} sx={{ textDecoration: "none" }} to="/admin/add-product" textAlign="center">Add new Product</Typography>
                    </MenuItem>
                  )}
                  {settings.map((setting) => (
                    <MenuItem key={setting.name} onClick={handleCloseUserMenu} component="a" href={setting.to}>
                      <Typography component="span" color={'text.primary'} sx={{ textDecoration: "none", width: "100%", textAlign: "center" }}>
                        {setting.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <>
                <Button variant='contained' onClick={() => navigate('/login')} sx={{ width: '100%' }}>Login</Button>
                <Button variant='contained' onClick={() => navigate("/track-order")} sx={{ width: '100%' }}>Track Order</Button>
              </>
            )}

            {cartItems?.length > 0 && (
              <Button onClick={() => navigate("/cart")} fullWidth>
                Cart ({cartItems.length})
              </Button>
            )}

            {!loggedInUser?.isAdmin && (
              <Button onClick={() => navigate("/wishlist")} fullWidth>
                Wishlist ({wishlistItems?.length})
              </Button>
            )}
          </Stack>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
