import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    order: null,  
};

const OrderDataSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrder: (state, action) => {
            console.log('Setting order:', action.payload); // Log the payload to verify
            state.order = action.payload;  // Set the order in Redux state
        },
       
    },
});

export const { setOrder } = OrderDataSlice.actions;

export default OrderDataSlice.reducer;
