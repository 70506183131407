import { Box, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { QRCodePng, appStorePng, googlePlayPng, facebookPng, instagramPng, twitterPng, linkedinPng } from '../../assets'
import SendIcon from '@mui/icons-material/Send';
import { MotionConfig, motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Footer = () => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const theme = useTheme()
  const is700 = useMediaQuery(theme.breakpoints.down(700)) // For small screens under 700px
  const is1024 = useMediaQuery(theme.breakpoints.down(1024)) // For medium screens under 1024px

  const labelStyles = {
    fontWeight: 300,
    cursor: 'pointer'
  }

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }

    setMessage("Thank you for subscribing!");
    setEmail("");
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.primary.main,
        paddingTop: "2rem",
        paddingLeft: is700 ? "1rem" : "3rem",
        paddingRight: is700 ? "1rem" : "3rem",
        paddingBottom: "1.5rem",
        rowGap: "5rem",
        color: theme.palette.primary.light,
        justifyContent: is700 || is1024 ? "center" : "space-around",  // Adjust for smaller screens
      }}
    >

      {/* Upper Section */}
      <Stack
        flexDirection={is700 || is1024 ? 'column' : 'row'}
        rowGap={'1rem'}
        justifyContent={is700 || is1024 ? 'center' : 'space-around'}
        flexWrap={'wrap'}
      >
        {/* Support Section */}
        <Stack
          rowGap={'1rem'}
          padding={'1rem'}
          sx={{ marginLeft: '2rem', marginRight: '1rem', flexBasis: is700 || is1024 ? '100%' : '300px' }}  // Adjust for smaller screens
        >
          <Typography variant='h6' sx={{ fontSize: is700 || is1024 ? '1rem' : '1.25rem' }}>Support</Typography>
          <Typography sx={{ ...labelStyles, cursor: 'default' }}>490 , Udyog Vihar Phase V, Gurgoan, Haryana - 122016.</Typography>
          <Typography sx={{ ...labelStyles, cursor: 'default',marginTop: '-0.6rem' }}>engineering@orginv8.com</Typography>
          <Typography sx={{ ...labelStyles, cursor: 'default',marginTop: '-0.6rem' }}>+918595576797</Typography>
        </Stack>

        {/* Registered Address Section */}
        <Stack
          rowGap={'1rem'}
          padding={'1rem'}
          sx={{ marginLeft: '2rem', marginRight: '1rem', flexBasis: is700 || is1024 ? '100%' : '300px' }}  // Adjust for smaller screens
        >
          <Typography variant='h6' sx={{ fontSize: is700 || is1024 ? '1rem' : '1.25rem' }}>Registered Address</Typography>
          <Typography sx={{ ...labelStyles, cursor: 'default' }}>
            B-19, 2<sup>nd</sup> Floor, Shiv Park, School Road, Khanpur, New Delhi - 110062
          </Typography>
        </Stack>

        {/* Quick Links Section */}
        <Stack
          rowGap={'1rem'}
          padding={'1rem'}
          sx={{ marginLeft: '2rem', marginRight: '-6rem', flexBasis: is700 || is1024 ? '100%' : '300px' }}
        >
          <Typography variant='h6' sx={{ fontSize: is700 || is1024 ? '1rem' : '1.25rem' }}>Quick Links</Typography>
          <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography sx={labelStyles}>Privacy Policy</Typography>
          </Link>
          <Link to="/refund-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography sx={labelStyles}>Refund Policy</Typography>
          </Link>
          <Link to="/about-us" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography sx={labelStyles}>About Us</Typography>
          </Link>
          <Link to="/shipping-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography sx={labelStyles}>Shipping Policy</Typography>
          </Link>
        </Stack>

      </Stack>

      {/* Lower Section */}
      <Stack
        alignSelf="left"
        sx={{
          mt: { xs: 2, sm: -6 },
          flexWrap: 'wrap',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'flex-start' },
          justifyContent: 'space-between',
          gap: { xs: 2, sm: 4 },
          paddingX: { xs: 2, sm: 4 },
        }}
      >
        <Typography
          color="GrayText"
          textAlign={{ xs: 'center', sm: 'left' }}
          sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px' } }}
        >
          &copy; Original Innovation LLP {new Date().getFullYear()}. All rights reserved
        </Typography>

        {/* Social Media Icons */}
        <Stack
          marginLeft={{ xs: 0, sm: 4 }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'row' },
            justifyContent: 'center',
            gap: { xs: 1, sm: 2 },
          }}
        >
          <MotionConfig whileHover={{ scale: 1.1 }} whileTap={{ scale: 1 }}>
            <motion.a href="https://www.facebook.com/profile.php?id=61566401565182" target="_blank" rel="noopener noreferrer">
              <motion.img
                style={{ cursor: 'pointer' }}
                src={facebookPng}
                alt="Facebook"
                width={is700 || is1024 ? 15 : 20}
                height={is700 || is1024 ? 15 : 20}
              />
            </motion.a>
            <motion.a href="https://x.com/LlpOriginal" target="_blank" rel="noopener noreferrer">
              <motion.img
                style={{ cursor: 'pointer' }}
                src={twitterPng}
                alt="Twitter"
                width={is700 || is1024 ? 15 : 20}
                height={is700 || is1024 ? 15 : 20}
              />
            </motion.a>
            <motion.a href="https://www.instagram.com/original_innovation_llp" target="_blank" rel="noopener noreferrer">
              <motion.img
                style={{ cursor: 'pointer' }}
                src={instagramPng}
                alt="Instagram"
                width={is700 || is1024 ? 15 : 20}
                height={is700 || is1024 ? 15 : 20}
              />
            </motion.a>
            <motion.a href="https://www.linkedin.com/in/original-innovation-llp-794968313/" target="_blank" rel="noopener noreferrer">
              <motion.img
                style={{ cursor: 'pointer' }}
                src={linkedinPng}
                alt="Linkedin"
                width={is700 || is1024 ? 15 : 20}
                height={is700 || is1024 ? 15 : 20}
              />
            </motion.a>
          </MotionConfig>
        </Stack>
      </Stack>


    </Stack>
  );
}
