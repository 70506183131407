// features/subadmin/subadminApi.js
import { CustomStorageManager } from '../../classes/storageManager';
import { axiosi } from '../../config/axios'; // Adjust the import as needed

// Function for subadmin login
export const loginSubAdmin = async (credentials) => {
    try {
        const res = await axiosi.post('https://orginv8.in:8443/subadmin/login', credentials);
       await CustomStorageManager.store('subadminToken', res.data.token);
        return res.data; // Return data for further use
    } catch (error) {
        const errorMessage = error.response ? error.response.data : 'Network Error';
        throw errorMessage; // Return the error message
    }
};

export const fetchSubAdmin = async () => {
    try {
        const res = await axiosi.get('https://orginv8.in:8443/subadmin/fetchSubadmin');
        return res.data; // Return data for further use
    } catch (error) {
        const errorMessage = error.response ? error.response.data : 'Network Error';
        throw errorMessage; // Return the error message
    }
};