import React, { useEffect, useState, useRef } from "react";
import { addSearchHistory, fetchDevicesBySubAdminId } from "../../features/subadmin/deviceApi";
import { useNavigate } from "react-router-dom";
import { CustomStorageManager } from "../../classes/storageManager";
import "./sub.css"; // Assuming the CSS is in the same folder
import { Chart } from "react-google-charts";
import {
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from '@mui/material';
import axios from "axios";
import { AddBox } from "@mui/icons-material";
import { wrap } from "framer-motion";
const SubAdminDashboard = () => {
    const [devices, setDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTab, setSelectedTab] = useState("searchDevices");
    const [subAdminId, setSubAdminId] = useState(null);
    const [newLimits, setNewLimits] = useState({});
    const [deviceStatus, setDeviceStatus] = useState({});
    const [updateButtonStatusMap, setUpdateButtonStatusMap] = useState({});
    const [restartButton, setRestartButton] = useState({});
    const [searchStr, setSearchStr] = useState('');
    const [previousSearches, setPreviousSearches] = useState([])
    const [subAdminData, setsubAdminData] = useState([]);
    // const [showTable, setShowTable] = useState({}); //hide in
    const [tableShow, setTableShow] = useState({});
    const navigate = useNavigate();
    const workerRef = useRef(null);
    const [searchRes, setSearchRes] = useState([]);
    const SearchOptions = {
        keys: ['macId', 'deviceModelno'],
        threshold: 0.3,
        shouldSort: true,
    };
    useEffect(() => {
        workerRef.current = new Worker(new URL('../ops/components/searchWorker.js', import.meta.url));
        console.log(workerRef);
        workerRef.current.onmessage = (e) => {
            setSearchRes(e.data);
        };
        return () => {
            workerRef.current.terminate();
        };
    }, []);
    useEffect(() => {
        const fetchSubAdminId = async () => {
            try {
                const id = CustomStorageManager.fetch("subadminId");
                console.log("subadmin id is ", id);
                setSubAdminId(id);
            } catch (err) {
                console.error("Failed to fetch subAdminId:", err);
            }
        };
        fetchSubAdminId();
    }, []);
    useEffect(() => {
        const fetchSubadminData = async () => {
            try {
                const id = CustomStorageManager.fetch("subadminId");
                const response = await fetchDevicesBySubAdminId(id);
                if (response) {
                    setsubAdminData(response.devices);
                    console.log("---> previous search data ", response['pre'])
                    setPreviousSearches(response.previousSearches)
                } else {
                    setError("Failed to fetch devices.");
                }
            } catch (err) {
                console.error("Failed to fetch subAdminId:", err);
            }
        };
        fetchSubadminData();
    }, []);
    useEffect(() => {
        if (subAdminId && selectedTab === "devices") {
            const getDevices = async () => {
                try {
                    const response = await fetchDevicesBySubAdminId(subAdminId);
                    if (response) {
                        setDevices(response);
                    } else {
                        setError("Failed to fetch devices.");
                    }
                } catch (err) {
                    console.error("Failed to fetch devices:", err);
                    setError("Failed to fetch devices.");
                } finally {
                    setIsLoading(false);
                }
            };
            getDevices();
        }
    }, [subAdminId, selectedTab]);

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchStr(value);
        handleSearch(value);
    };

    const handleSearch = (value) => {
        workerRef.current.postMessage({
            data: subAdminData,
            options: SearchOptions,
            searchStr: value,
        });
    };

    // useEffect(() => {
    //     let deviceFetchInterval;

    //     const fetchDevicesInterval = () => {
    //         if (subAdminId && selectedTab === "devices") {
    //             const getDevices = async () => {
    //                 try {
    //                     const response = await fetchDevicesBySubAdminId(subAdminId);
    //                     if (response) {
    //                         setDevices(response.devices);
    //                     } else {
    //                         setError("Failed to fetch devices.");
    //                     }
    //                 } catch (err) {
    //                     console.error("Failed to fetch devices:", err);
    //                     setError("Failed to fetch devices.");
    //                 }
    //             };

    //             getDevices();
    //         }
    //     };

    //     return () => {
    //         if (deviceFetchInterval) clearInterval(deviceFetchInterval);
    //     };
    // }, [subAdminId, selectedTab]);

    const checkDeviceStatus = async (macId) => {
        try {
            setDeviceStatus((prev) => ({
                ...prev,
                [macId]: { value: "loading", color: "blue" },
            }));
            const response = await fetch("https://orginv8.in:8443/device/ping", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ macId }),
            });

            const result = await response.json();
            const status = result.message === 1 ? "Online" : "Offline";
            const color = result.message === 1 ? "green" : "red";
            // Set status temporarily
            setDeviceStatus((prev) => ({
                ...prev,
                [macId]: { value: status, color: color },
            }));

            // Clear status after 3 seconds
            setTimeout(() => {
                setDeviceStatus((prev) => {
                    const updated = { ...prev };
                    delete updated[macId];
                    return updated;
                });
            }, 3000);
        } catch (err) {
            console.error("Error pinging device:", err);
            setDeviceStatus((prev) => ({
                ...prev,
                [macId]: "Error",
            }));

            // Clear status after 3 seconds
            setTimeout(() => {
                setDeviceStatus((prev) => {
                    const updated = { ...prev };
                    delete updated[macId];
                    return updated;
                });
            }, 3000);
        }
    };

    const handleSelect = async (macId) => {
        const id = CustomStorageManager.fetch("subadminId");
        await addSearchHistory(macId, id);
        navigate(`/device-monitor/${macId}`);
    };

    const renderSearchBar = () => (
        <List>
            <TextField
                label="Search by macId or name"
                variant="outlined"
                fullWidth
                value={searchStr}
                onChange={handleChange}
                sx={{ marginBottom: '10px' }}
            />
            {searchRes.length > 0 ? (
                searchRes.slice(0, 10).map((res, index) => (
                    <ListItem
                        key={index}
                        onClick={() => handleSelect(res?.item?.macId)}
                        divider
                        sx={{
                            padding: '10px',
                        }}
                    >
                        <ListItemText
                            primary={res?.item?.macId}
                            secondary={res?.item?.deviceModelno}
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        />
                    </ListItem>
                ))
            ) : (
                <Typography textAlign="center" color="textSecondary" sx={{ padding: '10px' }}>
                    No results found
                </Typography>
            )}

            {previousSearches.length > 0 && (
                <>
                    <Typography variant="h6" sx={{ marginTop: '20px' }}>
                        Based on previous searches
                    </Typography>
                    {previousSearches.map((macId, index) => (
                        <Box
                            key={index}
                            onClick={() => handleSelect(macId)}
                            divider
                            sx={{
                                padding: '10px',
                                cursor: 'pointer',
                                columnGap: 3,
                                rowGap: 1,
                                gridTemplateColumns: 'repeat(2, 1fr)',
                            }}
                        > <Box sx={{
                            border: '1px solid black',
                            padding: '6px',
                            borderRadius: '3px',
                            display: 'grid',
                        }}
                        ><Grid> Mac Id : {macId}</Grid>

                            </Box>
                        </Box>
                    ))}
                </>
            )}
        </List>
    );


    const handleTableChange = (device) => {
        navigate("/device-details", { state: { device } });
    };

    const handleNewLimitChange = (macId, capability, value) => {
        setNewLimits((prev) => ({
            ...prev,
            [macId]: {
                ...prev[macId],
                [capability]: value,
            },
        }));
    };

    const handleUpdateAllLimits = async (macId) => {
        const updatedLimits = newLimits[macId] || {};
        const device = devices.find((dev) => dev.macId === macId);

        if (!device) {
            alert("Device not found.");
            return;
        }
        const finalLimits = { ...device.limits, ...updatedLimits };

        try {
            setUpdateButtonStatusMap((prev) => ({
                ...prev,
                [macId]: {
                    value: "Loading",
                    color: "blue"
                }
            }));
            const response = await fetch("https://orginv8.in:8443/device/updateLimits", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ macId: macId, limits: finalLimits }),
            });
            if (!response.ok) {
                setUpdateButtonStatusMap((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Update failed",
                        color: "red"
                    }
                }));
            } else {
                setUpdateButtonStatusMap((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Update success",
                        color: "green"
                    }
                }));
            }

            const result = await response.json();
            console.log(`Updated limits for device ${macId}:`, result);

            setDevices((prevDevices) =>
                prevDevices.map((dev) =>
                    dev.macId === macId
                        ? {
                            ...dev,
                            limits: finalLimits,
                        }
                        : dev
                ));

            setNewLimits((prev) => ({
                ...prev,
                [macId]: {},
            }));
        } catch (err) {
            console.error(err);
            alert(`Error updating limits: ${err.message}`);
        } finally {
            setTimeout(() => {
                setUpdateButtonStatusMap((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Update",
                        color: "orange"
                    }
                }));
            }, 3000);
        }
    };

    const handleRestartDevice = async (macId) => {
        try {
            setRestartButton((prev) => ({
                ...prev,
                [macId]: {
                    value: "Restarting",
                    color: "blue"
                }
            }));

            const response = await fetch("https://orginv8.in:8443/device/restart", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ macId }),
            });

            const result = await response.json();
            console.log("Restart result:", result);

            if (result.success) {
                setRestartButton((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Restarted",
                        color: "green"
                    }
                }));
            } else {
                setRestartButton((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Failed",
                        color: "red"
                    }
                }));
            }
        } catch (err) {
            console.error("Error restarting device:", err);
        } finally {
            setTimeout(() => {
                setRestartButton((prev) => ({
                    ...prev,
                    [macId]: {
                        value: "Restart",
                        color: "orange"
                    }
                }));
            }, 3000);
        }
    };

    const renderDevices = () => (
        <div className="main">
            <h3 className="heading">Your Devices</h3>
            {isLoading && <p>Loading devices...</p>}
            {error && <p>Error: {error}</p>}
            {devices.length > 0 ? (

                <div className="device-card-grid">
                    {devices.map((device) => (
                        <div key={device.macId} className="device-card">
                            <div className="parent">
                                <div>
                                    <h4>{device.deviceModelno}</h4>
                                    <p><strong>MAC ID:</strong> {device.macId}</p>
                                    <p><strong>LAST UPDATED AT:</strong> {new Date(device.updatedAt).toLocaleString()}</p>
                                </div>
                                <div className="card-buttons">
                                    <button
                                        onClick={() => checkDeviceStatus(device.macId)}
                                        className="check-status-button"
                                        style={{
                                            backgroundColor: deviceStatus[device.macId]
                                                ? deviceStatus[device.macId].color
                                                : "orange",
                                        }}
                                    >
                                        {deviceStatus[device.macId]
                                            ? deviceStatus[device.macId].value
                                            : "Check Status"}
                                    </button>
                                    {/* <button
                                        onClick={() => handleUpdateAllLimits(device.macId)}
                                        style={{
                                            backgroundColor: updateButtonStatusMap[device.macId]
                                                ? updateButtonStatusMap[device.macId].color
                                                : "orange",
                                        }}
                                    >
                                        {updateButtonStatusMap[device.macId]
                                            ? updateButtonStatusMap[device.macId].value
                                            : "Update Limits"}
                                    </button> */}
                                    <button
                                        onClick={() => handleRestartDevice(device.macId)}
                                        style={{
                                            backgroundColor: restartButton[device.macId]
                                                ? restartButton[device.macId].color
                                                : "orange",
                                        }}
                                    >
                                        {restartButton[device.macId]
                                            ? restartButton[device.macId].value
                                            : "Restart"}
                                    </button>
                                </div>
                            </div>
                            <table className="capabilities-table">
                                <thead>
                                    <tr>
                                        <th>Capability</th>
                                        <th>Value</th>
                                        <th>Limit</th>
                                        <th>
                                            New Limit

                                            <button
                                                className="showBtn"
                                                onClick={() => handleTableChange(device)}
                                            >
                                                Show Details
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                {tableShow[device.macId] && (
                                    <tbody>
                                        {device.selectedCapabilities
                                            .map((capability) => capability.update)
                                            .sort(
                                                (a, b) =>
                                                    new Date(b.updatedAt) - new Date(a.updatedAt)
                                            )
                                            .slice(0, 1)
                                            .map((update, index) =>
                                                Object.entries(update).map(([key, value]) => (
                                                    <tr
                                                        key={`${device.macId}-${index}-${key}`}
                                                        className={
                                                            device.limits?.[key] < value
                                                                ? "highlight-row"
                                                                : ""
                                                        }
                                                    >
                                                        <td>{key}</td>
                                                        <td>{value}</td>
                                                        <td>{device.limits?.[key] || "N/A"}</td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="new-limit-input"
                                                                placeholder="Enter new limit"
                                                                value={
                                                                    newLimits[device.macId]?.[key] ||
                                                                    ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleNewLimitChange(
                                                                        device.macId,
                                                                        key,
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                    </tbody>
                                )}
                            </table>
                            {tableShow[device.macId] && (
                                <div className="graphs">
                                    {device.setKeys.map((key) => {
                                        const data = [];
                                        const finalList = {};

                                        device.selectedCapabilities.forEach((capability) => {
                                            const update = capability.update;
                                            if (!finalList[key]) {
                                                finalList[key] = [];
                                            }
                                            if (update[key]) {
                                                finalList[key].push([
                                                    new Date(capability.updatedAt).toLocaleString(),
                                                    update[key],
                                                ]);
                                            }
                                        });

                                        if (finalList[key].length > 0) {
                                            finalList[key].forEach((entry) => {
                                                data.push(entry);
                                            });

                                            return (
                                                <div
                                                    key={`${device.macId}-${key}-graph`}
                                                    className="graph-container"
                                                >
                                                    <h5>{key} Graph</h5>
                                                    <Chart
                                                        chartType="LineChart"
                                                        data={[
                                                            ["Time", "Value"],
                                                            ...data,
                                                        ]}
                                                        width="100%"
                                                        height="400px"
                                                        legendToggle
                                                    />
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>)}
                        </div>
                    ))}

                </div>
            )
                : (
                    !isLoading && <p>No devices found.</p>
                )}
        </div>
    );



    const handleLogout = () => {
        CustomStorageManager.clearAll();
        navigate("/subadmin/login");
    };

    return (
        <div className="dashboard-container">
            <div className="sidebar">
                <h3>Menu</h3>
                <ul>
                    <li onClick={() => setSelectedTab("devices")}>Devices</li>
                    <li onClick={() => setSelectedTab("planValidity")}>Plan Validity</li>
                    <li onClick={() => setSelectedTab("searchDevices")}>Search Devices</li>
                </ul>
                <button onClick={handleLogout} className="logout-button">Logout</button>
            </div>
            <div className="main-content">
                {selectedTab === "searchDevices" && renderSearchBar()}
                {selectedTab === "devices" && renderDevices()}
            </div>
        </div>
    );
};

export default SubAdminDashboard;




