
import React, { useEffect, useState, useRef } from "react";
import { fetchDevicesBySubAdminId } from "../../features/subadmin/deviceApi";

const SearchDevices = ({ subAdminData }) => {
    const [searchRes, setSearchRes] = useState([]);
    const workerRef = useRef(null);

    useEffect(() => {
        workerRef.current = new Worker(new URL('../ops/components/searchWorker.js', import.meta.url));
        console.log(workerRef);
        workerRef.current.onmessage = (e) => {
            setSearchRes(e.data);
        };

        return () => {
            workerRef.current.terminate();
        };
    }, []);


    return (
        <div className="search-devices-container">
            <h1>Search Devices</h1>
            <input type="text" placeholder="Enter device name" className="search-input" />
            <button className="search-button">Search</button>
            {/* Add logic to display search results */}
        </div>
    );
};

export default SearchDevices;
