import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Button, Stack, Box, Typography, Paper } from '@mui/material';
import Lottie from 'lottie-react';
import { resetCurrentOrder } from '../features/order/OrderSlice';
import { orderSuccessAnimation, notFoundPageAnimation } from '../assets';
import axios from 'axios';
import { CustomStorageManager } from '../classes/storageManager';
import Loader from "react-js-loader";
import { resetCartByUserIdAsync } from '../features/cart/CartSlice';
let hitFlag = 0;

const OrderSuccess = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [orderStatus, setOrderStatus] = useState('');
    const [orderId, setOrderId] = useState('');
    const [order, setOrder] = useState(null);
   
    const [parsedPaymentResponse, setParsedPaymentResponse] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchOrderData = async () => {
            const storedOrder = await CustomStorageManager.fetch('kalyanOrder');
            const order = storedOrder ? JSON.parse(storedOrder) : null;
            setOrder(order);  // Set the order in state
        };

        fetchOrderData();
    }, []);

    useEffect(() => {
        const getQueryParams = (url) => {
            return new URLSearchParams(url.search);
        };

        const queryParams = getQueryParams(location);
        const paymentResponse = queryParams.get('paymentResponse');
        const parsedResponse = paymentResponse ? JSON.parse(decodeURIComponent(paymentResponse)) : null;
        setParsedPaymentResponse(parsedResponse); // Set the parsed payment response

    }, [location]);

    useEffect(() => {
        const fetchOrderStatus = async () => {
            if (order && parsedPaymentResponse) {
                setLoading(true);
                const newOrder = {
                    user: order.user,
                    item: order.item,
                    address: order.address,
                    paymentMode: order.paymentMode,
                    total: order.total,
                    email: order.email,
                    guestFlag: order.guestFlag,
                    paymentResponse: parsedPaymentResponse,
                };

                try {
                    const res = await axios.post("https://orginv8.in:8443/orders", newOrder);
                    console.log("res", res)
                    setOrderId(res.data._id);
                    const paymentStatus = res.data.paymentDetails.ResponseCode; // Assuming paymentDetails.Status is the field for status

                    if (paymentStatus === '00') {
                        setOrderStatus('SUCCESS');
                        dispatch(resetCartByUserIdAsync(order.userId));  
                    } else {
                        setOrderStatus('FAILED');
                    }
                } catch (error) {
                    console.error("Error creating order:", error);
                    setOrderStatus('FAILED');
                } finally {
                    setLoading(false);
                }
            }
        };

        if (hitFlag === 0 && order && parsedPaymentResponse) {
            hitFlag = 1;
            fetchOrderStatus();
        }

    }, [order, parsedPaymentResponse]);

    const conformationMessage = orderStatus === 'SUCCESS'
        ? `Your Order #${orderId} is confirmed`
        : `Your Order #${orderId} is not confirmed`;

    const paymentStatusMessage = orderStatus === 'SUCCESS'
        ? 'Your order was successful! Thank you for your purchase.'
        : 'Payment failed. Please try again or contact support for assistance.';

    return (

        <Stack width="100vw" height="100vh" justifyContent="center" alignItems="center">
            {
                loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" >
                        <Loader type="spinner-circle" bgColor={"red"} size={100} />
                    </Box>

                ) : (
                    <Stack
                        component={Paper}
                        boxShadow="none"
                        rowGap={3}
                        elevation={1}
                        p={4}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box width="10rem" height="7rem">
                            <Lottie animationData={orderStatus === 'SUCCESS' ? orderSuccessAnimation : notFoundPageAnimation} />
                        </Box>

                        <Stack mt={2} textAlign="center" justifyContent="center" alignItems="center" rowGap={1}>
                            <Typography variant="h6" fontWeight={400}>
                                Hey User
                            </Typography>

                            <Typography variant="h5">
                                {conformationMessage}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {paymentStatusMessage}
                            </Typography>
                        </Stack>
                        {
                            !order.guestFlag ? (
                                orderStatus === 'SUCCESS' ? (
                                    <Button
                                        component={Link}
                                        to="/orders"
                                        onClick={() => dispatch(resetCurrentOrder())}
                                        size="small"
                                        variant="contained"
                                    >
                                        Check order status in my orders
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => navigate('/cart')}
                                        size="small"
                                        variant="contained"
                                    >
                                        Go to cart
                                    </Button>
                                )
                            ) : (
                                orderStatus === 'SUCCESS' ? (
                                    <Button
                                        component={Link}
                                      
                                        onClick={() => navigate('/track-order')}
                                        size="small"
                                        variant="contained"
                                    >
                                        Track Order
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => navigate('/')}
                                        size="small"
                                        variant="contained"
                                    >
                                        Go to homepage
                                    </Button>
                                )
                            )
                        }




                    </Stack>
                )
            }

        </Stack>
    );
};

export default OrderSuccess;
