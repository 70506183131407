import {axiosi} from '../../config/axios'


export const createChat=async(chatData)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/chat/createChat",chatData);
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

export const fetchActiveChats=async(searchData)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/chat/getActiveChats",searchData);
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

export const fetchChatMessages=async(searchData)=>{
    try{
        const res=await axiosi.post("https://orginv8.in:8443/message/getMessagesByOrderId",searchData);
        return res.data
    }catch(e){
        throw e.response.data
    }
}
export const addMessage=async(data)=>{
    try{
        const res=await axiosi.post("https://orginv8.in:8443/message/addMessage",data);
        return res.data
    }catch(e){
        throw e.response.data
    }
}