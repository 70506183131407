import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../../features/auth/AuthSlice";
import { Navigate } from "react-router-dom";
import { CustomStorageManager } from "../../classes/storageManager";

export const ProtectedSubAdmin = ({ children }) => {
    const subadminData = CustomStorageManager.fetch('subadminId');
    if (subadminData != null) {
        return children;
    }
    return <Navigate to="/subadmin/login" replace />;
};
