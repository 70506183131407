import { Button, Grid, Modal, Stack, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateAddressByIdAsync } from '../../address/AddressSlice';

const EditAddressOpenModel = ({ openModal, useForm, editingAddress, setOpenModal, setGuestUserAddress, loggedInUser }) => {

    const dispatch = useDispatch()
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            type: editingAddress?.type || '',
            street: editingAddress?.street || '',
            postalCode: editingAddress?.postalCode || '',
            country: editingAddress?.country || '',
            phoneNumber: editingAddress?.phoneNumber || '',
            state: editingAddress?.state || '',
            city: editingAddress?.city || '',
            gstNumber: editingAddress?.bussinessAddress?.gstNumber || '',
            gstCompanyName: editingAddress?.bussinessAddress?.gstCompanyName || '',
            gstAddress: editingAddress?.bussinessAddress?.gstAddress || '',
            email:editingAddress?.email||''
        }
    })

    const handleCloseModal = () => {
        setOpenModal(false);
  
    };
    // Edit Address Model //
    useEffect(() => {
        if (openModal && editingAddress) {
            // Reset form values to the new editingAddress
            reset({
                type: editingAddress?.type || '',
                street: editingAddress?.street || '',
                postalCode: editingAddress?.postalCode || '',
                country: editingAddress?.country || '',
                phoneNumber: editingAddress?.phoneNumber || '',
                state: editingAddress?.state || '',
                city: editingAddress?.city || '',
                gstNumber: editingAddress?.bussinessAddress?.gstNumber || '',
                gstCompanyName: editingAddress?.bussinessAddress?.gstCompanyName || '',
                gstAddress: editingAddress?.bussinessAddress?.gstAddress || '',
                email: editingAddress?.email || ''

            });
        }
    }, [openModal, editingAddress, reset])

    const onSubmit = (data) => {
        const addressEditData = {
            type: data.type,
            street: data.street,
            country: data.country,
            phoneNumber: data.phoneNumber,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            email: data.email,
            bussinessAddress: {
                gstNumber: data.gstNumber,
                gstCompanyName: data.gstCompanyName,
                gstAddress: data.gstAddress
            }
        }

        const update = { ...addressEditData, _id: editingAddress._id }
        let guestAddresses = JSON.parse(localStorage.getItem("guestAddressesData")) || [];

        const index = guestAddresses.findIndex(item => item.id === editingAddress.id);

        if (index !== -1) {
            guestAddresses[index] = { ...guestAddresses[index], ...update };
            localStorage.setItem("guestAddressesData", JSON.stringify(guestAddresses));
            setGuestUserAddress([...guestAddresses])
        } else {
            dispatch(updateAddressByIdAsync(update))
        }
        setOpenModal(false)
    };
    return (
        <>
            <Modal open={openModal}
                aria-labelledby="edit-address-modal"
                aria-describedby="edit-address-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <div style={{
                    width: '650px',
                    margin: '4px auto',
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    maxHeight: '80vh', 
                    overflowY: 'auto', 
                    boxSizing: 'border-box',
                    scrollbarWidth: 'thin', 
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                            Edit Address
                        </Typography>
                        <CloseIcon onClick={handleCloseModal} sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                color: 'red',
                            },
                        }} />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {/* First Column */}
                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Name</Typography>
                                <TextField
                                    fullWidth

                                    {...register("type", {
                                        required: "Type is required", required: "Name is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.type}
                                    helperText={errors.type?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Street</Typography>
                                <TextField
                                    fullWidth

                                    {...register("street", {
                                        required: "Street is required",
                                        required: "Street is required", minLength: { value: 1, message: "Street must be at least 1 characters" },
                                        maxLength: { value: 6, message: "Street must not exceed 6 characters" }
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.street}
                                    helperText={errors.street?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Postal Code</Typography>
                                <TextField
                                    fullWidth
                                    type="number"
                                    {...register("postalCode", {
                                        required: "Postal Code is required", minLength: { value: 6, message: "Postal Code must be at least 6 characters" },
                                        maxLength: { value: 6, message: "Postal Code must not exceed 6 characters" }
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.postalCode}
                                    helperText={errors.postalCode?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Country</Typography>
                                <TextField
                                    fullWidth

                                    {...register("country", {
                                        required: "Country is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.country}
                                    helperText={errors.country?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Phone Number</Typography>
                                <TextField
                                    fullWidth
                                    type="number"
                                    {...register("phoneNumber", {
                                        required: "Phone number is required",
                                        pattern: {
                                            value: /^\d+$/,
                                            message: "Phone number must be numeric value"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Phone number must be at least 10 digits"
                                        },
                                        maxLength: {

                                            value: 10,
                                            message: "Phone number maximum 10 digits"

                                        }
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.phoneNumber}
                                    helperText={errors.phoneNumber?.message}
                                />
                            </Grid>


                            {loggedInUser._id == process.env.REACT_APP_GUESTUSER_ID && (
                                <Grid item xs={12} sm={6}>
                                    <Typography gutterBottom>Email</Typography>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        {...register("email", {
                                            required: "Email is required", pattern: {
                                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                message: "Invalid email address",
                                            }
                                        })}
                                        sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>State</Typography>
                                <TextField
                                    fullWidth
                                    type='text'
                                    {...register("state", {
                                        required: "State is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.state}
                                    helperText={errors.state?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>City</Typography>
                                <TextField
                                    fullWidth
                                    type='text'
                                    {...register("city", {
                                        required: "City is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.city}
                                    helperText={errors.city?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>GST Number</Typography>
                                <TextField
                                    fullWidth
                                    type="text"
                                    {...register("gstNumber", {
                                        required: false,
                                        pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Kindly provide me correct GST Number",
                                        }
                                    })}
                                    sx={{ "& .MuiInputBase-root": { height: "30px" } }}
                                    error={!!errors.gstNumber}
                                    helperText={errors.gstNumber ? errors.gstNumber.message : ""}


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Company Name</Typography>
                                <TextField
                                    fullWidth
                                    type="text"
                                    {...register("gstCompanyName", {
                                        required: false,
                                        pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Company Name cannot consist of only numbers.",
                                        },
                                    })}
                                    error={!!errors.gstCompanyName}
                                    helperText={errors?.gstCompanyName?.message}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Company Address</Typography>
                                <TextField
                                    fullWidth
                                    type="text"
                                    {...register("gstAddress", {
                                        required: false,
                                        pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Company address cannot consist of only numbers.",

                                        },
                                    })}
                                    error={!!errors.gstAddress}
                                    helperText={errors?.gstAddress?.message}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                            Save Changes
                        </Button>
                    </form>

                </div>
            </Modal>
        </>
    )
}

export default EditAddressOpenModel
