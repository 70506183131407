import { Stack, TextField, Typography, Button, Menu, MenuItem, Select, Grid, FormControl, Radio, Paper, IconButton, Box, useTheme, useMediaQuery, Modal } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useEffect, useState } from 'react'
import { Cart } from '../../cart/components/Cart'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { addAddressAsync, deleteAddressByIdAsync, fetchAddressByUserIdAsync, selectAddressStatus, selectAddresses } from '../../address/AddressSlice'
import { selectLoggedInUser } from '../../auth/AuthSlice'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { createOrderAsync, initialpaymentAsync, selectCurrentOrder, selectOrderStatus } from '../../order/OrderSlice'
import { resetCartByUserIdAsync, selectCartItems } from '../../cart/CartSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SHIPPING, TAXES } from '../../../constants'
import { motion } from 'framer-motion'
import { setOrder } from '../../order/OrderDataSlice'
import { CustomStorageManager } from '../../../classes/storageManager'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditAddressOpenModel from './EditAddressOpenModel'
import { v4 as uuidv4 } from 'uuid';


export const Checkout = () => {
    const status = ''
    const addresses = useSelector(selectAddresses)
    const [selectedAddress, setSelectedAddress] = useState(addresses[0])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('online')
    const { register, handleSubmit, watch, reset, setValue, formState: { errors }, reset: resetForm } = useForm()
    const dispatch = useDispatch()
    const loggedInUser = useSelector(selectLoggedInUser)
    const addressStatus = useSelector(selectAddressStatus)
    const navigate = useNavigate()
    const cartItems = useSelector(selectCartItems)
    const orderStatus = useSelector(selectOrderStatus)
    const currentOrder = useSelector(selectCurrentOrder)
    const orderTotal = cartItems.reduce((acc, item) => (item.product.price * item.quantity) + acc, 0)
    const theme = useTheme()
    const is900 = useMediaQuery(theme.breakpoints.down(900))
    const is480 = useMediaQuery(theme.breakpoints.down(480))
    const [guestUserAddress, setGuestUserAddress] = useState()
    const [openModal, setOpenModal] = useState(false);
    const [editingAddress, setEditingAddress] = useState(null);

    useEffect(() => {
        fetchAddressByUserIdAsync()
    }, [])

    useEffect(() => {
        if (addressStatus === 'fulfilled') {
            reset()
        }
        else if (addressStatus === 'rejected') {
            alert('Error adding your address')
        }
    }, [addressStatus])

    useEffect(() => {
        if (currentOrder && currentOrder?._id) {
            dispatch(resetCartByUserIdAsync(loggedInUser?._id))
            navigate(`/order-success/${currentOrder?._id}`)
        }
    }, [currentOrder])

    useEffect(() => {
        const savedAddresses = JSON.parse(localStorage.getItem('guestAddressesData')) || [];
        setGuestUserAddress(savedAddresses);

    }, []);

    const handleEditGuestUserAddress = (id) => {
        let data = JSON.parse(localStorage.getItem("guestAddressesData")) || [];
        const item = data.find(item => item.id === id);
        setEditingAddress(item)
        setOpenModal(true);

    }
    const handleDeleteGuestUserAddress = (id) => {
        const savedAddresses = JSON.parse(localStorage.getItem('guestAddressesData')) || []
        const updatedAddresses = savedAddresses.filter(address => address.id !== id);
        localStorage.setItem('guestAddressesData', JSON.stringify(updatedAddresses));
        setGuestUserAddress(updatedAddresses);
        setSelectedAddress()
    }

    const handleEditAddress = (addressId) => {
        const data = addresses.find((address) => address._id === addressId);
        setEditingAddress(data);
        setOpenModal(true);
    }
    const handleDeleteAddress = (id) => {
        dispatch(deleteAddressByIdAsync(id))
        setSelectedAddress()
    }
    const handleAddAddress = (data) => {
        const addressData = {
            type: data.type,
            street: data.street,
            country: data.country,
            phoneNumber: data.phoneNumber,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            email: data.email,
            bussinessAddress: {
                gstNumber: data.gstNumber,
                gstCompanyName: data.gstCompanyName,
                gstAddress: data.gstAddress
            }
        }

        if (loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID) {
            const guestAddress = { ...addressData, user: loggedInUser._id, id: uuidv4() };
            const existingGuestAddresses = JSON.parse(localStorage.getItem('guestAddressesData')) || [];
            const addedGuestAddresses = [...existingGuestAddresses, guestAddress];
            localStorage.setItem('guestAddressesData', JSON.stringify(addedGuestAddresses));
            setGuestUserAddress(addedGuestAddresses)

        } else {
            const address = { ...addressData, user: loggedInUser._id };
            dispatch(addAddressAsync(address));
        }
        resetForm();
    };

    const handleCreateOrder = async () => {
        if (!selectedAddress) {
            alert("Please select a valid address before proceeding.");
            return;
        }
        const order = {
            user: loggedInUser._id,
            item: cartItems,
            address: selectedAddress,
            paymentMode: selectedPaymentMethod,
            total: orderTotal + SHIPPING + TAXES,
            email: loggedInUser.email,
            guestFlag: loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID,
            item: cartItems.map(item => ({
                productId: item.product._id,
                quantity: item.quantity,
            })),
        }

        if (loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID) {
            if (selectedPaymentMethod === 'online') {

                const order = {
                    user: loggedInUser._id,
                    item: cartItems,
                    address: {
                        city: selectedAddress.city,
                        country: selectedAddress.country,
                        id: selectedAddress.id,
                        phoneNumber: selectedAddress.phoneNumber,
                        postalCode: selectedAddress.postalCode,
                        state: selectedAddress.state,
                        street: selectedAddress.street,
                        type: selectedAddress.type,
                        user: selectedAddress.user,

                    },
                    paymentMode: selectedPaymentMethod,
                    total: orderTotal + SHIPPING + TAXES,
                    guestFlag: loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID,
                    item: cartItems.map(item => ({
                        productId: item.product._id,
                        quantity: item.quantity,
                    })),
                }

                const payload = {
                    address: order.address,
                    products: order.item,
                    returnUrl: "https://orginv8.in/order-success",
                    userId: order.user,
                    guestFlag: order.guestFlag,
                    paymentMode: selectedPaymentMethod,
                    total: orderTotal + SHIPPING + TAXES,
                    email: selectedAddress.email,
                    user: loggedInUser._id,
                    item: cartItems,
                }
                dispatch(setOrder(order));
                await CustomStorageManager.store('kalyanOrder', JSON.stringify(payload));
                navigate('/payment_page', { state: { payload } });

            }
        }
        else {

            if (selectedPaymentMethod === 'online') {
                const address = {
                    city: order.address.city,
                    state: order.address.state,
                    street: order.address.street,
                    postalCode: order.address.postalCode,
                    phoneNumber: order.address.phoneNumber,
                    type: order.address.type,
                    country: order.address.country
                }
                const payload = {
                    address: address,
                    products: order.item,
                    returnUrl: "https://orginv8.in/order-success",
                    userId: order.user,
                    guestFlag: order.guestFlag,
                    paymentMode: selectedPaymentMethod,
                    total: orderTotal + SHIPPING + TAXES,
                    email: loggedInUser.email,
                    user: loggedInUser._id,
                    item: cartItems,
                }
                dispatch(setOrder(order));
                await CustomStorageManager.store('kalyanOrder', JSON.stringify(payload));
                navigate('/payment_page', { state: { payload } });

            }

        }
    }

    return (
        <Stack flexDirection={'row'} p={2} rowGap={10} justifyContent={'center'} flexWrap={'wrap'} mb={'5rem'} mt={2} columnGap={4} alignItems={'flex-start'}>

            {/* left box */}
            <Stack rowGap={4}>

                {/* heading */}
                <Stack flexDirection={'row'} columnGap={is480 ? 0.3 : 1} alignItems={'center'}>
                    <motion.div whileHover={{ x: -5 }}>
                        <IconButton component={Link} to={"/cart"}><ArrowBackIcon fontSize={is480 ? "medium" : 'large'} /></IconButton>
                    </motion.div>
                    <Typography variant='h4'>Shipping Information</Typography>
                </Stack>

                {/* address form */}
                <Stack component={'form'} noValidate rowGap={2} onSubmit={handleSubmit(handleAddAddress)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>Name</Typography>
                                <TextField
                                    placeholder='Eg. Home, Business'
                                    {...register("type", {
                                        required: "Name is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    error={!!errors.type}
                                    helperText={errors?.type?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>Street</Typography>
                                <TextField
                                    {...register("street", {
                                        required: "Street is required",
                                        minLength: {
                                            value: 1,
                                            message: "Street number must be at least 1 digits"
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: "Street number maximum 6 digits"
                                        }
                                    })}
                                    error={!!errors.street}
                                    helperText={errors?.street?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>Country</Typography>
                                <TextField
                                    {...register("country", {
                                        required: "Country is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    error={!!errors.country}
                                    helperText={errors?.country?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>Phone Number</Typography>
                                <TextField
                                    type='number'
                                    {...register("phoneNumber", {
                                        required: "Phone number is required",
                                        pattern: {
                                            value: /^\d+$/,
                                            message: "Phone number must be numeric value"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Phone number must be at least 10 digits"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Phone number maximum 10 digits"
                                        }
                                    })}
                                    error={!!errors.phoneNumber}
                                    helperText={errors?.phoneNumber?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        {loggedInUser._id == process.env.REACT_APP_GUESTUSER_ID && (
                            <Grid item xs={12}>
                                <Stack>
                                    <Typography gutterBottom>Email</Typography>
                                    <TextField
                                        type="text"
                                        {...register("email", {
                                            required: "Email is required", pattern: {
                                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                message: "Invalid email address",
                                            }
                                        })}
                                        error={!!errors.email}
                                        helperText={errors?.email?.message}
                                        fullWidth
                                    />
                                </Stack>
                            </Grid>
                        )}

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>City</Typography>
                                <TextField
                                    {...register("city", {
                                        required: "City is required",
                                        pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    error={!!errors.city}
                                    helperText={errors?.city?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>State</Typography>
                                <TextField
                                    {...register("state", {
                                        required: "State is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    error={!!errors.state}
                                    helperText={errors?.state?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>Postal Code</Typography>
                                <TextField
                                    type='number'
                                    {...register("postalCode", {
                                        required: "Postal code is required",
                                        minLength: {
                                            value: 6,
                                            message: "Postal code must be at least 6 digits"
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: "Postal code must be at most 6 digits"
                                        }
                                    })}
                                    error={!!errors.postalCode}
                                    helperText={errors?.postalCode?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>GST Number (Optional)</Typography>
                                <TextField
                                    {...register("gstNumber", {
                                        required: false, pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Kindly provide me correct GST Number",
                                        }
                                    })}
                                    error={!!errors.gstNumber}
                                    helperText={errors?.gstNumber?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>Company Name (Optional)</Typography>
                                <TextField
                                    {...register("gstCompanyName", {
                                        required: false,
                                        pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Company Name cannot consist of only numbers.",
                                        }
                                    })}
                                    error={!!errors.gstCompanyName}
                                    helperText={errors?.gstCompanyName?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography gutterBottom>Business Address (Optional)</Typography>
                                <TextField
                                    type='text'
                                    {...register("gstAddress", {
                                        required: false,
                                        pattern: {
                                            value: /^(?!\d+$).*/,
                                            message: "Company address cannot consist of only numbers.",
                                        },
                                    })}
                                    error={!!errors.gstAddress}
                                    helperText={errors?.gstAddress?.message}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Stack flexDirection={'row'} alignSelf={'flex-end'} columnGap={1} mt={2}>
                        <LoadingButton loading={status === 'pending'} type='submit' variant='contained'>Add</LoadingButton>
                        <Button color='error' variant='outlined' onClick={() => reset()}>Reset</Button>
                    </Stack>
                </Stack>

                {/* existing address */}


                <Stack direction="row" spacing={4} justifyContent="space-between">
                    {/* Shipping Address */}
                    <Stack rowGap={3} width="50%">
                        <Stack>
                            <Typography variant="h6"> Shipping Address</Typography>
                            <Typography variant="body2" color="text.secondary">Choose from existing Addresses</Typography>
                        </Stack>
                        <Grid container gap={2} justifyContent="flex-start" alignContent="flex-start">

                            {(loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID && guestUserAddress) ? (


                                guestUserAddress.map((address) => (
                                    <FormControl item key={address._id}>
                                        <Stack p={2} width="22rem" height="18rem" rowGap={2} component={Paper} elevation={1}>
                                            <Stack flexDirection="row" alignItems="center">
                                                <Radio checked={selectedAddress === address} name="billingAddressRadioGroup" onChange={() => setSelectedAddress(address)} />
                                                <Typography>{address.type}</Typography>
                                                <Stack direction="row" ml="auto" spacing={3}>
                                                    <EditIcon onClick={() => handleEditGuestUserAddress(address.id)} sx={{ cursor: 'pointer' }} />
                                                    <DeleteIcon onClick={() => handleDeleteGuestUserAddress(address.id)} sx={{ cursor: 'pointer' }} />
                                                </Stack>
                                            </Stack>

                                            {/* Shipping address details */}
                                            <Stack>
                                                <Typography>{address.street}</Typography>
                                                <Typography>{address.state}, {address.city}, {address.country}, {address.postalCode}</Typography>
                                                <Typography>{address.phoneNumber}</Typography>
                                            </Stack>

                                            <Stack>
                                                <Typography><span style={{ fontWeight: '500' }}>GST:</span> {address.bussinessAddress.gstNumber}</Typography>
                                                <Typography><span style={{ fontWeight: '500' }}>Company Name:</span> {address.bussinessAddress.gstCompanyName}</Typography>
                                                <Typography><span style={{ fontWeight: '500' }}>Bussiness Adddress:</span> {address.bussinessAddress.gstAddress}</Typography>
                                            </Stack>
                                        </Stack>
                                    </FormControl>
                                ))

                            ) : (

                                addresses.map((address) => (
                                    <FormControl item key={address._id}>
                                        <Stack p={2} width="22rem" height="18rem" rowGap={2} component={Paper} elevation={1}>
                                            <Stack flexDirection="row" alignItems="center">
                                                <Radio checked={selectedAddress === address} name="billingAddressRadioGroup" onChange={() => setSelectedAddress(address)} />
                                                <Typography>{address.type}</Typography>
                                                <Stack direction="row" ml="auto" spacing={3}>
                                                    <EditIcon onClick={() => handleEditAddress(address._id)} sx={{ cursor: 'pointer' }} />
                                                    <DeleteIcon onClick={() => handleDeleteAddress(address._id)} sx={{ cursor: 'pointer' }} />
                                                </Stack>
                                            </Stack>

                                            {/* Shipping address details */}
                                            <Stack>
                                                <Typography>{address.street}</Typography>
                                                <Typography>{address.state}, {address.city}, {address.country}, {address.postalCode}</Typography>
                                                <Typography>{address.phoneNumber}</Typography>
                                            </Stack>
                                            <Stack>
                                                <Typography><span style={{ fontWeight: '500' }}>GST:</span> {address.bussinessAddress.gstNumber}</Typography>
                                                <Typography><span style={{ fontWeight: '500' }}>Company Name:</span> {address.bussinessAddress.gstCompanyName}</Typography>
                                                <Typography><span style={{ fontWeight: '500' }}>Bussiness Adddress:</span> {address.bussinessAddress.gstAddress}</Typography>
                                            </Stack>
                                        </Stack>
                                    </FormControl>
                                ))
                            )}
                            {/* Edit Open Model Here */}
                            <EditAddressOpenModel
                                openModal={openModal}
                                useForm={useForm}
                                editingAddress={editingAddress}
                                setOpenModal={setOpenModal}
                                setGuestUserAddress={setGuestUserAddress}
                                loggedInUser={loggedInUser}
                            />
                        </Grid>
                    </Stack>
                    {/* Billing Address Section */}
                    {
                        (guestUserAddress?.gstNumber || addresses?.some(address => address.gstNumber)) ? (
                            <Stack rowGap={3} width="50%">
                                <Stack>
                                    <Typography variant="h6">Billing Address</Typography>
                                    <Typography variant="body2" color="text.secondary">Choose from existing Addresses</Typography>
                                </Stack>
                                <Grid container gap={2} justifyContent="flex-start" alignContent="flex-start">
                                    {(loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID && guestUserAddress) ? (
                                        <FormControl item>
                                            <Stack p={2} width="26rem" height="15rem" rowGap={2} component={Paper} elevation={1}>
                                                <Grid container spacing={2}>
                                                    {/* Address Block */}
                                                    <Grid item xs={6}>
                                                        <Stack flexDirection="row" alignItems="center">
                                                            <Radio
                                                                checked={selectedAddress === guestUserAddress}
                                                                name="addressRadioGroup"
                                                                onChange={() => setSelectedAddress(guestUserAddress)}
                                                            />
                                                            <Typography>{guestUserAddress.type}</Typography>
                                                        </Stack>
                                                        {/* Address details */}
                                                        <Stack>
                                                            <Typography>{guestUserAddress.street}</Typography>
                                                            <Typography>
                                                                {guestUserAddress.state}, {guestUserAddress.city}, {guestUserAddress.country}, {guestUserAddress.postalCode}
                                                            </Typography>
                                                            <Typography>{guestUserAddress.phoneNumber}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                    {/* GST Block */}
                                                    <Grid item xs={6} textAlign="left" marginTop={1}>
                                                        <Stack flexDirection="row" alignItems="center">
                                                            <Typography sx={{ fontSize: '1rem' }}>GST Details:</Typography>

                                                        </Stack>
                                                        <Stack>
                                                            <Typography>{guestUserAddress.gstNumber}</Typography>
                                                            <Typography>{guestUserAddress.gstCompanyName}</Typography>
                                                            <Typography>{guestUserAddress.gstAddress}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </FormControl>
                                    ) : (
                                        addresses.map((address) => (
                                            <FormControl item key={address._id}>
                                                <Stack p={2} width="26rem" height="15rem" rowGap={2} component={Paper} elevation={1}>
                                                    <Grid container spacing={2}>
                                                        {/* Address Block */}
                                                        <Grid item xs={6}>
                                                            <Stack flexDirection="row" alignItems="center">
                                                                <Radio
                                                                    checked={selectedAddress === guestUserAddress}
                                                                    name="addressRadioGroup"
                                                                    onChange={() => setSelectedAddress(guestUserAddress)}
                                                                />
                                                                <Typography>{address.type}</Typography>
                                                            </Stack>
                                                            {/* Address details */}
                                                            <Stack>
                                                                <Typography>{address.street}</Typography>
                                                                <Typography>
                                                                    {address.state}, {address.city}, {address.country}, {address.postalCode}
                                                                </Typography>
                                                                <Typography>{address.phoneNumber}</Typography>
                                                            </Stack>
                                                        </Grid>
                                                        {/* GST Block */}
                                                        <Grid item xs={6} textAlign="left" marginTop={1}>
                                                            <Stack flexDirection="row" alignItems="center">
                                                                <Typography sx={{ fontSize: '1rem' }}>GST Details:</Typography>

                                                            </Stack>
                                                            <Stack>
                                                                <Typography>{address.gstNumber}</Typography>
                                                                <Typography>{address.gstCompanyName}</Typography>
                                                                <Typography>{address.gstAddress}</Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>

                                                </Stack>
                                            </FormControl>
                                        ))
                                    )}
                                </Grid>
                            </Stack>
                        ) : null
                    }

                </Stack>

                {/* payment methods */}
                {/* <Stack rowGap={3}>

                        <Stack>
                            <Typography variant='h6'>Payment Methods</Typography>
                            <Typography variant='body2' color={'text.secondary'}>Please select a payment method</Typography>
                        </Stack>

                        <Stack rowGap={2}>

                            <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                <Radio value={selectedPaymentMethod} name='paymentMethod' checked={selectedPaymentMethod === 'COD'} onChange={() => setSelectedPaymentMethod('COD')} />
                                <Typography>Cash</Typography>
                            </Stack>

                            <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                <Radio value={selectedPaymentMethod} name='paymentMethod' checked={selectedPaymentMethod === 'CARD'} onChange={() => setSelectedPaymentMethod('CARD')} />
                                <Typography>Card</Typography>
                            </Stack>

                        </Stack>


                    </Stack> */}
            </Stack>

            {/* right box */}
            <Stack width={is900 ? '100%' : 'auto'} alignItems={is900 ? 'flex-start' : ''}>
                <Typography variant='h4'>Order summary</Typography>
                <Cart checkout={true} />
                <LoadingButton fullWidth loading={orderStatus === 'pending'} variant='contained' onClick={handleCreateOrder} size='large'>Pay and order</LoadingButton>
            </Stack>

        </Stack>
    )
}