import { Box, Button, Grid, IconButton, Modal, Paper, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import { updateOrderByIdAsync } from '../../order/OrderSlice';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';


const GuestUserTrackOrder = () => {
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState()
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [orderAddressData, setOrderAddressData] = useState()
    const theme = useTheme()
    const is1200 = useMediaQuery(theme.breakpoints.down("1200"))
    const is768 = useMediaQuery(theme.breakpoints.down("768"))
    const is660 = useMediaQuery(theme.breakpoints.down(660))
    const is480 = useMediaQuery(theme.breakpoints.down("480"))
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm()

    const handleChange = (e) => {
        setSearch(e.target.value);
    };
    const handleSearchData = async () => {
        if (!search.trim()) {
            return;
        }
        try {
            const res = await axios.get(`https://orginv8.in:8443/orders/fetchOrderById/`, {
                params: { orderId: search },
            });
            setOrder(res.data.data);
        } catch (error) {
            console.error("Error fetching order:", error.response ? error.response.data : error.message);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setOrderAddressData(null);

    };
    // Change Address Model //
    useEffect(() => {
        if (orderAddressData) {
            setValue('street', orderAddressData.street || '');
            setValue('phoneNumber', orderAddressData.phoneNumber || '');
            setValue('postalCode', orderAddressData.postalCode || '');
            setValue('state', orderAddressData.state || '');
            setValue('city', orderAddressData.city || '');
            setValue('type', orderAddressData.type || '');
            setValue('country', orderAddressData.country || '');
        } else {
            reset();
        }
    }, [orderAddressData, setValue, reset]);
    
    // Change order address     
    const handleChangeOrderAddress = (orderId, addressData) => {
        setSelectedOrderId(orderId);
        setOrderAddressData(addressData);
        setOpenModal(true)
    }


    const onSubmit = async (data) => {
        const changeAdddressData = {
            _id: selectedOrderId,
            address: {
                type: data.type,
                street: data.street,
                postalCode: data.postalCode,
                country: data.country,
                phoneNumber: data.phoneNumber,
                state: data.state,
                city: data.city,
            }
        };
        try {
            await dispatch(updateOrderByIdAsync(changeAdddressData));

            const res = await axios.get(`https://orginv8.in:8443/orders/fetchOrderById/`, {
                params: { orderId: selectedOrderId },
            });
            setOrder(res.data.data);

            setOpenModal(false);
        } catch (error) {
            console.error("Error updating order:", error.response ? error.response.data : error.message);
        }
    };

    return (
        <>
            <Box
                sx={{
                    maxWidth: 400,
                    margin: '0 auto',
                    padding: '10px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                    '@media (max-width: 600px)': {
                        maxWidth: '90%',
                        padding: '12px',
                    },
                }}
            >
                <Typography variant="h5" gutterBottom textAlign="center" color="primary" sx={{
                    fontFamily: '"Poppins", sans-serif',
                    fontSize: '1.5rem',
                }}>
                    Search Order Id
                </Typography>

                <Box sx={{ marginBottom: '15px' }}>
                    <TextField
                        label="Search by order Id"
                        variant="outlined"
                        fullWidth
                        value={search}
                        onChange={handleChange}
                        sx={{
                            marginBottom: '8px',
                        }}
                    />

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSearchData}
                        fullWidth
                        sx={{
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px',
                        }}
                    >
                        Search
                    </Button>
                </Box>
            </Box>


            <Stack justifyContent={'center'} alignItems={'center'}>
                <Stack width={is1200 ? "auto" : "60rem"} p={is480 ? 2 : 4} mb={'5rem'}>

                    {/* orders */}
                    <Stack mt={5} rowGap={5}>

                        {/* orders mapping */}
                        {
                            order ? (
                                <Stack p={is480 ? 0 : 2} component={is480 ? "" : Paper} elevation={1} rowGap={2} key={order._id}>
                                    {/* upper */}
                                    <Stack flexDirection={'row'} rowGap={'1rem'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                        <Stack flexDirection={'row'} columnGap={4} rowGap={'1rem'} flexWrap={'wrap'}>
                                            <Stack>
                                                <Typography>Order Number</Typography>
                                                <Typography color={'text.secondary'}>{order._id}</Typography>
                                            </Stack>

                                            <Stack>
                                                <Typography>Date Placed</Typography>
                                                <Typography color={'text.secondary'}>{new Date(order.createdAt).toDateString()}</Typography>
                                            </Stack>

                                            <Stack>
                                                <Typography>Total Amount</Typography>
                                                <Typography>₹{order.total}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Stack>
                                            <Typography>Item: {order.item.length}</Typography>
                                        </Stack>
                                    </Stack>

                                    {/* Order Address */}

                                    <Stack rowGap={2}>
                                        {
                                            // Ensure order.address exists before calling map()
                                            order.address && order.address.length > 0 ? (
                                                order.address.map((addressData, index) => (

                                                    <Stack key={index} mt={2} flexDirection={'row'} rowGap={is768 ? '2rem' : ''} columnGap={4} flexWrap={is768 ? "wrap" : "nowrap"}>
                                                        <Stack rowGap={1} width={'100%'} flexGrow={1}>
                                                            <Stack><Typography> <span style={{ fontWeight: '500' }}>Delivery Address</span></Typography></Stack>
                                                            <Stack>
                                                                <Typography>  <span style={{ fontWeight: '500' }}>{addressData.type}</span></Typography>
                                                                <Typography>{addressData.street}, {addressData.city}- {addressData.postalCode},</Typography>
                                                                <Typography>  {addressData.state}, {addressData.country} </Typography>
                                                                <Typography>
                                                                    <span style={{ fontWeight: '500' }}>Phone number </span>{addressData.phoneNumber}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                        {
                                                            order.status === "Pending" && (
                                                                <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" width="100%" mt={-5}>
                                                                    <Button size='small' variant='outlined' onClick={() => handleChangeOrderAddress(order._id, addressData)}>

                                                                        Change Address
                                                                    </Button>
                                                                </Stack>
                                                            )
                                                        }

                                                    </Stack>



                                                ))
                                            ) : (
                                                <Typography>No address</Typography>
                                            )
                                        }
                                    </Stack>
                                    {/* Open Modal */}

                                    <Modal open={openModal}
                                        aria-labelledby="edit-address-modal"
                                        aria-describedby="edit-address-modal-description"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                        <div style={{
                                            width: '650px',
                                            margin: '4px auto',
                                            padding: '20px',
                                            backgroundColor: 'white',
                                            borderRadius: '8px',
                                            maxHeight: '80vh',
                                            overflowY: 'auto',
                                            boxSizing: 'border-box',
                                            scrollbarWidth: 'thin',
                                        }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Change Address
                                                </Typography>
                                                <CloseIcon onClick={handleCloseModal} sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        color: 'red',
                                                    },
                                                }} />
                                            </div>

                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <Grid container spacing={2}>
                                                    {/* First Column */}
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography gutterBottom>Name</Typography>
                                                        <TextField
                                                            fullWidth

                                                            {...register("type", {
                                                                required: "Type is required", required: "Name is required", pattern: {
                                                                    value: /^[a-zA-Z\s]*$/,
                                                                    message: "Only letters are allowed",
                                                                },
                                                            })}
                                                            sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                                            error={!!errors.type}
                                                            helperText={errors.type?.message}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Typography gutterBottom>Street</Typography>
                                                        <TextField
                                                            fullWidth

                                                            {...register("street", {
                                                                required: "Street is required",
                                                                required: "Street is required", minLength: { value: 1, message: "Street must be at least 1 characters" },
                                                                maxLength: { value: 6, message: "Street must not exceed 6 characters" }
                                                            })}
                                                            sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                                            error={!!errors.street}
                                                            helperText={errors.street?.message}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Typography gutterBottom>Postal Code</Typography>
                                                        <TextField
                                                            fullWidth
                                                            type="number"
                                                            {...register("postalCode", {
                                                                required: "Postal Code is required", minLength: { value: 6, message: "Postal Code must be at least 6 characters" },
                                                                maxLength: { value: 6, message: "Postal Code must not exceed 6 characters" }
                                                            })}
                                                            sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                                            error={!!errors.postalCode}
                                                            helperText={errors.postalCode?.message}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Typography gutterBottom>Country</Typography>
                                                        <TextField
                                                            fullWidth

                                                            {...register("country", {
                                                                required: "Country is required", pattern: {
                                                                    value: /^[a-zA-Z\s]*$/,
                                                                    message: "Only letters are allowed",
                                                                },
                                                            })}
                                                            sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                                            error={!!errors.country}
                                                            helperText={errors.country?.message}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Typography gutterBottom>Phone Number</Typography>
                                                        <TextField
                                                            fullWidth
                                                            type="number"
                                                            {...register("phoneNumber", {
                                                                required: "Phone number is required",
                                                                pattern: {
                                                                    value: /^\d+$/,
                                                                    message: "Phone number must be numeric value"
                                                                },
                                                                minLength: {
                                                                    value: 10,
                                                                    message: "Phone number must be at least 10 digits"
                                                                },
                                                                maxLength: {

                                                                    value: 10,
                                                                    message: "Phone number maximum 10 digits"

                                                                }
                                                            })}
                                                            sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                                            error={!!errors.phoneNumber}
                                                            helperText={errors.phoneNumber?.message}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Typography gutterBottom>State</Typography>
                                                        <TextField
                                                            fullWidth
                                                            type='text'
                                                            {...register("state", {
                                                                required: "State is required", pattern: {
                                                                    value: /^[a-zA-Z\s]*$/,
                                                                    message: "Only letters are allowed",
                                                                },
                                                            })}
                                                            sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                                            error={!!errors.state}
                                                            helperText={errors.state?.message}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography gutterBottom>City</Typography>
                                                        <TextField
                                                            fullWidth
                                                            type='text'
                                                            {...register("city", {
                                                                required: "City is required", pattern: {
                                                                    value: /^[a-zA-Z\s]*$/,
                                                                    message: "Only letters are allowed",
                                                                },
                                                            })}
                                                            sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                                            error={!!errors.city}
                                                            helperText={errors.city?.message}
                                                        />
                                                    </Grid>

                                                </Grid>
                                                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                                                    Save Changes
                                                </Button>
                                            </form>

                                        </div>
                                    </Modal>


                                    {/* middle */}
                                    <Stack rowGap={2}>
                                        {
                                            // Ensure order.item exists before calling map()
                                            order.item && order.item.length > 0 ? (
                                                order.item.map((product) => (
                                                    <Stack mt={2} flexDirection={'row'} rowGap={is768 ? '2rem' : ''} columnGap={4} flexWrap={is768 ? "wrap" : "nowrap"} key={product.product._id}>
                                                        <Stack>
                                                            <img style={{ width: "100%", aspectRatio: is480 ? 3 / 2 : 1 / 1, objectFit: "contain" }} src={product.product.images[0]} alt={product.product.title} />
                                                        </Stack>

                                                        <Stack rowGap={1} width={'100%'}>
                                                            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                                                <Stack>
                                                                    <Typography variant='h6' fontSize={'1rem'} fontWeight={500}>{product.product.title}</Typography>
                                                                    <Typography variant='body1' fontSize={'.9rem'} color={'text.secondary'}>{product.product.brand.name}</Typography>
                                                                    <Typography color={'text.secondary'} fontSize={'.9rem'}>Qty: {product.quantity}</Typography>
                                                                </Stack>
                                                                <Typography>₹{product.product.price}</Typography>
                                                            </Stack>

                                                            <Typography color={'text.secondary'}>{product.product.description}</Typography>

                                                            <Stack mt={2} alignSelf={is480 ? "flex-start" : 'flex-end'} flexDirection={'row'} columnGap={2}>
                                                                <Button size='small' component={Link} to={`/product-details/${product.product._id}`} variant='outlined'>View Product</Button>


                                                            </Stack>

                                                        </Stack>

                                                    </Stack>
                                                ))
                                            ) : (
                                                <Typography>No items in this order</Typography>
                                            )
                                        }
                                    </Stack>

                                    {/* lower */}
                                    <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'}>
                                        <Typography mb={2}>Status : {order.status}</Typography>
                                    </Stack>
                                </Stack>
                            ) : (
                                <Typography>No orders available</Typography> // Fallback message when no orders exist
                            )
                        }




                    </Stack>

                </Stack>



            </Stack>
        </>

    );
}
export default GuestUserTrackOrder;
