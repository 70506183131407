import GuestUserTrackOrder from "../features/GuestData/components/GuestUserTrackOrder"
import { Navbar } from "../features/navigation/components/Navbar"


const GuestUserOrderPage = () => {

  return (
    <>
      <Navbar />
      <GuestUserTrackOrder />
    </>
  )
}

export default GuestUserOrderPage
