import { Button, Grid, Modal, Stack, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form'
import { useEffect } from 'react';
import { updateOrderById } from '../OrderApi';
import { useDispatch } from 'react-redux';
import { updateOrderByIdAsync } from '../OrderSlice';


const ModalChangeAddress = ({ openModal, handleCloseModal, setOpenModal, orderAddressData, selectedOrderId }) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            type: orderAddressData?.type || '',
            street: orderAddressData?.street || '',
            postalCode: orderAddressData?.postalCode || '',
            country: orderAddressData?.country || '',
            phoneNumber: orderAddressData?.phoneNumber || '',
            state: orderAddressData?.state || '',
            city: orderAddressData?.city || '',  
     
        } 
    })

    // Change Address Model //
    useEffect(() => {
        if (openModal && orderAddressData) {
            // Reset form values to the new editingAddress
            reset({
                type: orderAddressData?.type || '',
                street: orderAddressData?.street || '',
                postalCode: orderAddressData?.postalCode || '',
                country: orderAddressData?.country || '',
                phoneNumber: orderAddressData?.phoneNumber || '',
                state: orderAddressData?.state || '',
                city: orderAddressData?.city || '',
            
            });
        }
    }, [openModal, orderAddressData, reset])

    const onSubmit = async (data) => {
        const changeAdddressData = {
            _id: selectedOrderId,
            address: {
                type: data.type,
                street: data.street,
                postalCode: data.postalCode,
                country: data.country,
                phoneNumber: data.phoneNumber,
                state: data.state,
                city: data.city,
            }
        }
        await updateOrderById(changeAdddressData)
        dispatch(updateOrderByIdAsync(changeAdddressData))
        setOpenModal(false)

    };
    return (
        <>
          <Modal open={openModal}
                aria-labelledby="edit-address-modal"
                aria-describedby="edit-address-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <div style={{
                    width: '650px',
                    margin: '4px auto',
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    maxHeight: '80vh', 
                    overflowY: 'auto', 
                    boxSizing: 'border-box',
                    scrollbarWidth: 'thin', 
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                           Change Address
                        </Typography>
                        <CloseIcon onClick={handleCloseModal} sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                color: 'red',
                            },
                        }} />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {/* First Column */}
                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Name</Typography>
                                <TextField
                                    fullWidth

                                    {...register("type", {
                                        required: "Type is required", required: "Name is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.type}
                                    helperText={errors.type?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Street</Typography>
                                <TextField
                                    fullWidth

                                    {...register("street", {
                                        required: "Street is required",
                                        required: "Street is required", minLength: { value: 1, message: "Street must be at least 1 characters" },
                                        maxLength: { value: 6, message: "Street must not exceed 6 characters" }
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.street}
                                    helperText={errors.street?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Postal Code</Typography>
                                <TextField
                                    fullWidth
                                    type="number"
                                    {...register("postalCode", {
                                        required: "Postal Code is required", minLength: { value: 6, message: "Postal Code must be at least 6 characters" },
                                        maxLength: { value: 6, message: "Postal Code must not exceed 6 characters" }
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.postalCode}
                                    helperText={errors.postalCode?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Country</Typography>
                                <TextField
                                    fullWidth

                                    {...register("country", {
                                        required: "Country is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.country}
                                    helperText={errors.country?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>Phone Number</Typography>
                                <TextField
                                    fullWidth
                                    type="number"
                                    {...register("phoneNumber", {
                                        required: "Phone number is required",
                                        pattern: {
                                            value: /^\d+$/,
                                            message: "Phone number must be numeric value"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Phone number must be at least 10 digits"
                                        },
                                        maxLength: {

                                            value: 10,
                                            message: "Phone number maximum 10 digits"

                                        }
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.phoneNumber}
                                    helperText={errors.phoneNumber?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>State</Typography>
                                <TextField
                                    fullWidth
                                    type='text'
                                    {...register("state", {
                                        required: "State is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.state}
                                    helperText={errors.state?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom>City</Typography>
                                <TextField
                                    fullWidth
                                    type='text'
                                    {...register("city", {
                                        required: "City is required", pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters are allowed",
                                        },
                                    })}
                                    sx={{ '& .MuiInputBase-root': { height: '30px' } }}
                                    error={!!errors.city}
                                    helperText={errors.city?.message}
                                />
                            </Grid>
                        
                        </Grid>
                        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                            Save Changes
                        </Button>
                    </form>

                </div>
            </Modal>
        </>
    )
}

export default ModalChangeAddress

